import Vue from 'vue'
import Vuex from 'vuex'

import StudentHome from './module/student/home'
import StudentUser from './module/student/user'
import StudentMenu from './module/student/menu'
import StudentCourse from './module/student/course'

import TeacherMenu from './module/teacher/menu'
import TeacherHome from './module/teacher/home'
import TeacherCourse from './module/teacher/course'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headerNotice: ''
  },
  getters: {
    getHeaderNotice: state => state.headerNotice
  },
  mutations: {
    SET_HEADER_NOTICE(state, message) {
      state.headerNotice = message;
    }
  },
  actions: {
    setHeaderNotice(context, message) {
      context.commit('SET_HEADER_NOTICE', message)
    }
  },
  modules: {
    StudentHome,
    StudentUser,
    StudentMenu,
    StudentCourse,
    TeacherMenu,
    TeacherHome,
    TeacherCourse
  }
})