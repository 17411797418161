export default {
    methods: {
        openLoadingView(text) {
            if (!text) {
                text = "Loading";
            }
            this.loadingView = this.$loading({
                lock: true,
                text: text
            });
        },
        getIsNull(val) {
            if (val == null || val === '') {
                return false;
            } else {
                return true;
            }
        },
        getMultipleSelectionIsNull(val) {
            if (val == null || val.length <= 0) {
                return true;
            } else {
                return false;
            }
        },
        getIds(array, name) {
            let ids = '';
            array.forEach(m => {
                ids += m[name] + ','
            });
            ids = ids.substring(0, ids.length - 1);
            return ids;
        },
        formatMixin(fmt, data) {
            var o = {
                "M+": data.getMonth() + 1,                 //月份
                "d+": data.getDate(),                    //日
                "H+": data.getHours(),                   //小时
                "m+": data.getMinutes(),                 //分
                "s+": data.getSeconds(),                 //秒
                "q+": Math.floor((data.getMonth() + 3) / 3), //季度
                "S": data.getMilliseconds()             //毫秒
            };
            if (/(y+)/.test(fmt))
                fmt = fmt.replace(RegExp.$1, (data.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt))
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        },
        formatToLocalDateTimeMixin(format, date) {
            date = new Date(date)
            if (format) {
                return this.formatMixin(format, date);
            } else {
                format = "yyyy-MM-dd HH:mm:ss";
                return this.format(format, date);
            }
        },
        getPermission(permissionUrl, callback) {
            let json = localStorage.getItem('permissionLink');
            let permissionArr = JSON.parse(json);
            let p = permissionArr.find(p => p.permission === permissionUrl);
            if (p != null && p != '') {
                if (callback) {
                    callback();
                }
            }
        }
    },
    data() {
        return {
            loadingView: null,
            // superMallConsts : commons.consts,
            opt: {//遮罩层
                lock: false,
                text: '正在加载.....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            userRouts: [],//当前用户所拥有的权限
            routsKeyName: 'menuList'
        }
    }
}