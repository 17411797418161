import {
    TEACHER_HOME_ROUTE
} from "@/tools/const"

const state = {
    // home首页 userinfo个人信息 tax报税信息 search
    t_home_routeSwitch: TEACHER_HOME_ROUTE.HOME
}

const getters = {
    getTeacherRouteSwitch: state => state.t_home_routeSwitch
}

const mutations = {
    SET_TEACHER_ROUTE_SWITCH(state, value) {
        state.t_home_routeSwitch = value;
    }
}

const actions = {
    set_teacher_route_switch({
        commit
    }, value) {
        commit('SET_TEACHER_ROUTE_SWITCH', value);
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}