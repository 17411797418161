const state = {
    // 切换菜单
    // 首页菜单1， 普通课程2 , 毕业大作业/毕业设计3
    stuMenuSwitch: localStorage.stu_menu_switch ? JSON.parse(localStorage.stu_menu_switch) : '1',
    // 菜单下的功能，控制显示隐藏
    sysPermissions: [],

    stu_notice: 0, // 通知公告
    stu_course_notice: 0, // 课程公告
}

const getters = {
    getStuMenuSwitch: state => state.stuMenuSwitch,
    getSysPer: state => state.sysPermissions,
    getStuNotice: state => state.stu_notice,
    getStuCourseNotice: state => state.stu_course_notice,
}

const mutations = {
    SET_STU_MENU_SWITCH(state, num) {
        state.stuMenuSwitch = num;
        localStorage.setItem('stu_menu_switch', JSON.stringify(state.stuMenuSwitch))
    },
    SET_SYS_PER(state, list) {
        state.sysPermissions = list;
    },
    SET_STU_NOTICE(state, num) {
        state.stu_notice = num;
    },
    SET_STU_COURSE_NOTICE(state, num) {
        state.stu_course_notice = num;
    }
}

const actions = {
    set_stu_menu_switch({
        commit
    }, num) {
        commit('SET_STU_MENU_SWITCH', num);
    },
    set_sys_per(context, list) {
        context.commit('SET_SYS_PER', list);
    },
    set_stu_notice({
        commit
    }, num) {
        commit('SET_STU_NOTICE', num);
    },
    set_stu_course_notice({
        commit
    }, num) {
        commit('SET_STU_COURSE_NOTICE', num)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}