<template>
  <div v-if="false">
    <!--引入头部-->
    <div>
      <ContinuingHead></ContinuingHead>
      <router-view></router-view>
    </div>
    <!---头部图片-->
    <!-- <div class="header">
      【CMS管理系统】
      <span class="loginer" style="float:right;cursor:pointer;" @click="login()">登陆 / 注册</span>
    </div>-->
    <!--登录弹框 -->
    <el-dialog :visible.sync="dialogFormVisible" center :append-to-body="true" :lock-scroll="false">
      <!--用户名登录表单-->
      <div>
        <h2 style="padding:0.1rem;text-align:center;">账号登陆</h2>
      </div>
    </el-dialog>
    <div style="background-color:#f2ecd9">
      <div class="top">
        <div class="company"></div>
        <div>
          <div class="hotspot">
            <!-- <i class=""> -->
            <div style="font-size:0.2rem;text-align: left;">
              <img src="@/assets/sy.png" alt style="margin-top:2%;margin-left:3%" />
            </div>
            <div class="similarities">
              <p class="hoster">热点新闻</p>
            </div>
            <div class="Suddenly">
              <div class="schedule">
                <el-carousel height="274px">
                  <el-carousel-item v-for="item in 4" :key="item">
                    <h3 class="small">{{ item }}</h3>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="national">
                <img src="@/assets/c3.png" alt />
              </div>
            </div>
            <div class="Suddenlyion">
              <div class="Republic">
                <i class="el-icon-document" style="margin-right:0.3rem"></i>新闻速递
              </div>
              <div class="mobilize">
                <ul>
                  <li class="broadcaster">
                    <img src="@/assets/redian.png" alt />教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动
                    <p
                      class="contenter"
                    >为做好“稳就业”工作，切实帮扶湖北高校毕业生顺利就业创业，教育部4月8日印发通知，启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动。</p>
                    <p style="color:#88aef7;font-size: 0.35rem;text-align:right">[详情]</p>
                  </li>
                  <li class="broadcaster">
                    <img src="@/assets/redian.png" alt />教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动
                    <p
                      class="contenter"
                    >为做好“稳就业”工作，切实帮扶湖北高校毕业生顺利就业创业，教育部4月8日印发通知，启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动。</p>
                    <p style="color:#88aef7;font-size: 0.35rem;text-align:right">[详情]</p>
                  </li>
                  <li class="broadcaster">
                    <img src="@/assets/redian.png" alt />教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动
                    <p
                      class="contenter"
                    >为做好“稳就业”工作，切实帮扶湖北高校毕业生顺利就业创业，教育部4月8日印发通知，启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动。</p>
                    <p style="color:#88aef7;font-size: 0.35rem;text-align:right">[详情]</p>
                  </li>
                  <li class="broadcast">省教育厅召开学习贯彻 《关于深化新时代学校思想政治理论课改革创新的若干意见》座谈会</li>
                  <li class="broadcast">省教育厅召开学习贯彻 《关于深化新时代学校思想政治理论课改革创新的若干意见》座谈会</li>
                  <li class="broadcast">省教育厅召开学习贯彻 《关于深化新时代学校思想政治理论课改革创新的若干意见》座谈会</li>
                  <li class="broadcast">省教育厅召开学习贯彻 《关于深化新时代学校思想政治理论课改革创新的若干意见》座谈会</li>
                  <li class="broadcast">省教育厅召开学习贯彻 《关于深化新时代学校思想政治理论课改革创新的若干意见》座谈会</li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="journalism">
              <img src="@/assets/20200512130813.png" alt />
            </div>
          </div>
          <div class="hotspoter">
            <div class="similarities">
              <p class="hoster">通知公告</p>
            </div>
            <div class="news-list">
              <router-link to>
                <img src="@/assets/new.gif" alt style="float: right;" />
                <p class="slideBox">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</p>
              </router-link>
              <ul>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
                <router-link to>
                  <li class="broadcasters">教育部启动实施全国高校与湖北高校毕业生就业创业工作“一帮一”行动</li>
                </router-link>
              </ul>
              <div class="notifications">更多通知</div>
            </div>
          </div>
        </div>

        <div class="list_words">
          <div class="similarities">
            <p class="hoster" style="margin-left:1.5rem">合作高校</p>
            <p class="host">查看更多</p>
          </div>
          <div style="margin-top:1.5rem">
            <img src="@/assets/E0B01352DE274FFEB5D1D8AC87865E8D.png" style="width:13%" alt />
            <img src="@/assets/0E1CA0462AD847E2F1ECE099FB9F658D.png" style="width:13%" alt />
            <img src="@/assets/AEE98EB739A46AB06C02307E236069C7.png" style="width:13%" alt />
            <img src="@/assets/4660A53B645D7E5D09860BE200E2BC02.png" style="width:13%" alt />
            <img src="@/assets/E0B01352DE274FFEB5D1D8AC87865E8D.png" style="width:13%" alt />
            <img src="@/assets/E0B01352DE274FFEB5D1D8AC87865E8D.png" style="width:13%" alt />
            <img src="@/assets/E0B01352DE274FFEB5D1D8AC87865E8D.png" style="width:13%" alt />
          </div>
        </div>
        <div>
          <div class="academic">
            <div class="similarities">
              <p class="hoster">学术交流</p>
              <p class="host">查看更多</p>
            </div>
            <div class="index-list">
              <ul>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>

                    <i class="el-icon-arrow-right" style="float:right"></i>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="Solicit">
          <div class="similarities">
            <p class="hoster">诚招咨询</p>
            <p class="host">查看更多</p>
          </div>
          <div class="index-list">
            <ul>
              <li
                style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
              >
                <router-link to style="color:#76766e">
                  <p class="lookcountion">2019年12月职业与继续教育要闻简报</p>
                  <p style="float:right;font-size:0.2rem">2020-5-12</p>
                </router-link>
              </li>
              <li
                style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
              >
                <router-link to style="color:#76766e">
                  <p class="lookcountion">2019年12月职业与继续教育要闻简报</p>
                  <p style="float:right;font-size:0.2rem">2020-5-12</p>
                </router-link>
              </li>
              <li
                style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
              >
                <router-link to style="color:#76766e">
                  <p class="lookcountion">2019年12月职业与继续教育要闻简报</p>
                  <p style="float:right;font-size:0.2rem">2020-5-12</p>
                </router-link>
              </li>
              <li
                style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
              >
                <router-link to style="color:#76766e">
                  <p class="lookcountion">2019年12月职业与继续教育要闻简报</p>
                  <p style="float:right;font-size:0.2rem">2020-5-12</p>
                </router-link>
              </li>
            </ul>
          </div>
          </div>-->
          <div class="Solicit">
            <div class="similarities">
              <p class="hoster">诚招资讯</p>
              <p class="host">查看更多</p>
            </div>
            <div class="index-list">
              <ul>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
                <li
                  style="text-align:left;padding:0.2rem;border-bottom:1px solid #fff;margin-top:0.8rem"
                >
                  <router-link to style="color:#76766e">
                    <p class="lookcount">2019年12月职业与继续教育要闻简报</p>
                    <p style="float:right;font-size:0.1rem">2020-5-12</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="Friendship">
            <div class="similarities">
              <p class="hoster">友情链接</p>
              <p class="host">查看更多</p>
            </div>
            <div class="blogroll" v-for="(v,k) in Viewlink" :key="k">
              <p class="ministry">{{v.friendshipname}}</p>
            </div>
          </div>
        </div>
        <div class="navigation">
          <div class="similarities">
            <p class="hoster">高校导航</p>
          </div>
          <div style="text-align:left;padding:1rem">
            <p class="minist" v-for="(v,k) in navigat" :key="k">{{v.schoolname}}</p>
          </div>
        </div>
      </div>
      <div>
        <ContinuingBottom></ContinuingBottom>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import ContinuingHead from "@/components/ContinuingHead";
import ContinuingBottom from "@/components/ContinuingBottom";
// @ is an alias to /src
export default {
  components: {
    ContinuingHead,
    ContinuingBottom
  },
  data() {
    return {
      dialogFormVisible: false,
      input: "",
      Viewlink:[{
        friendshipname:""
      }],
      navigat:[{
        schoolname:""
      }]
    };
  },
  created() {
    this.$router.push('/Online/index')
    // this.Viewlinks();
    // this.navigation()
  },
  methods: {
    //查看友情链接  View links
    Viewlinks(){
      axios({
        method: "get",
        url:
          "viewservice/t-friendship/QueryTFriendship"
      })
        .then(res => {
          this.Viewlink = res.data
        })
        .catch(res => {});
    },
    //高校导航
    navigation(){
       axios({
        method: "get",
        url:
          "/viewservice/t-school/queryTSchool"
      })
        .then(res => {
          this.navigat = res.data
          // this.pape = res.data
        })
        .catch(res => {});
    },
    //登陆
    login() {
      this.dialogFormVisible = true;
      
    }
    //提交
  }
};
</script>
<style scoped>
.header {
  background-color: #006834;
  padding: 0.2rem 0.5rem;
  color: #fff;
  font-size: 0.18rem;
  text-align: left;
}
.loginer {
  text-align: right;
}
.top {
  width: 70%;
  margin: 0 auto;
  margin-top: 2%;
}
.top .company {
  background-color: #a0c7c5;
  padding: 1.05%;
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}
.hotspot {
  width: 68%;
  padding: 1%;
  display: inline-block;
  background-image: url(../assets/01_03.png);
  background-repeat: no-repeat;
  margin-top: 2%;
  margin-bottom: 2%;
  background-size: cover;
}
/* .hotspot img{
  width: 5%;
} */
.hotspoter {
  width: 27%;
  padding-top: 8%;
  /* display: inline-block; */
  /* background-color: #e5e6d3; */
  margin-left: 3%;
  float: right;
  margin-top: 2%;
  background-image: url(../assets/beijing.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.hoster {
  font-size: 0.9rem;
  /* text-align: left; */
  /* float: left; */
  margin-left: 3%;
  background-color: #006005;
  display: inline-block;
  padding: 0.6rem;
  /* background-image: url(../assets/soubiao.png);
  background-repeat: no-repeat; */
  color: #fff;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  /* background-size:auto */
}
.similarities {
  border-bottom: 3px solid #72abab;
  text-align: left;
}
.Suddenly {
  margin-top: 4%;
  width: 57%;
  display: inline-block;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 274px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.schedule {
  width: 100%;
  /* float: left; */
}
.national {
  width: 100%;
  margin-top: 4%;
  background-image: url(../assets/01_11.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.national img {
  width: 66%;
  border-radius: 3%;
}
.Suddenlyion {
  width: 40%;
  margin-top: 4%;
  /* display: inline-block; */
  margin-left: 1%;
  float: right;
}
.Republic {
  padding: 0.8rem;
  background-color: #f6f7f2;
  font-size: 0.92rem;
  text-align: left;
}
.mobilize {
  padding: 0.5rem;
  background-color: #edefe1;
}
.mobilize ul {
  list-style: none;
}
.mobilize ul .broadcaster {
  font-size: 0.85rem;
  font-family: SimHei;
  text-align: left;
  color: #659b7c;
}
.contenter {
  font-size: 0.8rem;
  color: #83847e;
  margin-top: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.broadcast {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.9rem;
  color: #6e6f6d;
  font-size: 0.9rem;
  list-style: inside;
}
.journalism {
  margin-top: 3%;
  width: 100%;
}
.journalism img {
  width: 100%;
}
.news-list {
  padding: 0.5rem;
}
.slideBox {
  font-size: 0.8rem;
  color: #5ca97c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #5ca97c;
  margin-top: 0.3rem;
}
.broadcasters {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1.29rem;
  color: #6e6f6d;
  font-size: 0.9rem;
  list-style: inside;
  border-bottom: 1px solid #fff;
}
a {
  text-decoration: none;
}
.notifications {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #008776;
  border-radius: 3%;
  color: #fff;
}
.list_words {
  padding: 1rem;
  text-align: left;
  margin-bottom: 2%;
  background-color: #e5e6d3;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.list_words img {
  margin-left: 0.9%;
}
.academic {
  width: 36%;
  padding: 1rem;
  background-color: #e5e6d3;
  display: inline-block;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
  float: left;
}
.Solicit {
  width: 30%;
  padding: 1rem;
  background-color: #e5e6d3;
  display: inline-block;
  margin-left: 1rem;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
  /* float: left; */
}
.Friendship {
  width: 24%;
  padding: 1rem;
  background-color: #e5e6d3;
  /* display: inline-block; */
  margin-left: 1rem;
  float: right;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.host {
  font-size: 0.9rem;
  /* text-align: right; */
  float: right;
  margin-left: 3%;
  background-color: #fff;
  /* display: inline-block; */
  padding: 0.6rem;
  /* background-image: url(../assets/soubiao.png);
  background-repeat: no-repeat; */
  color: #378278;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
}
.index-list ul {
  list-style: none;
  padding: 1rem;
  font-size: 0.9rem;
}
.lookcount {
  display: inline-block;
}
.lookcountion {
  display: inline-block;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}
.blogroll {
  padding: 0.2rem;
}
.ministry {
  padding: 0.7rem 1rem;
  font-size: 0.9rem;
  border: 1px solid #a7b9a2;
  margin-top: 0.6rem;
}
.navigation {
  background-color: #e5e6d3;
  padding: 0.5rem;
  margin-top: 3rem;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.minist {
  padding: 1rem 2rem;
  font-size: 0.9rem;
  border: 1px solid #a7b9a2;
  margin-top: 0.6rem;
  display: inline-block;
  margin-left: 1rem;
  color: #418576;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .top {
    width: 90% !important;
  }
  .hotspot {
    width: 100% !important;
  }
  .Suddenly {
    width: 100% !important;
  }
  .Suddenlyion {
    width: 100% !important;
    float: none !important;
  }
  .hotspoter {
    width: 100% !important;
    float: none !important;
    padding-top: 40% !important;
    margin-left: 0 !important;
  }
  .academic {
    width: 90% !important;
    float: none !important;
  }
  .Solicit {
    width: 90% !important;
    float: none !important;
    margin-left: 0 !important;
    margin-top: 2rem !important;
    margin-right: 0 !important;
  }
  .Friendship {
    width: 90% !important;
    float: none !important;
    margin-left: 0 !important;
    margin-top: 2rem !important;
  }
  .navigation {
    width: 95% !important;
  }
  .minist {
    margin-left: 0.3rem !important;
  }
  .lookcount {
    font-size: 0.3rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top {
    width: 96%;
  }
  .academic {
    width: 31%;
  }
  .Solicit {
    width: 27%;
    float: none;
    display: inline-block;
  }
  .Friendship {
    float: right;
  }
}
/* @media (min-width: 298px)  {
   .top{
    width:90% !important;
  }
  .hotspot {
    width: 100% !important;
  }
  .Suddenly {
    width: 100% !important;
  }
  .Suddenlyion {
    width: 100% !important;
    float: none !important;
  }
  .hotspoter {
    width: 100% !important;
    float: none !important;
    padding-top: 40% !important;
    margin-left: 0 !important;
  }
  .academic{
     width: 90% !important;
    float: none !important;
  }
  .Solicit{
     width: 90% !important;
    float: none !important;
    margin-left: 0 !important;
    margin-top: 2rem !important;
    margin-right:0 !important;
  }
  .Friendship{
    width: 90% !important;
    float: none !important;
     margin-left: 0 !important;
     margin-top: 2rem !important;
  }
  .navigation{
      width: 95% !important;
  }
  .minist{
    margin-left: 0.3rem !important;
  }
  .lookcount{
    font-size: 0.3rem !important;
  } */
/* @media (min-width: 992px)  {
   .top{
    width:90% !important;
  }
  .hotspot {
    width: 100% !important;
  }
  .Suddenly {
    width: 100% !important;
  }
  .Suddenlyion {
    width: 100% !important;
    float: none !important;
  }
  .hotspoter {
    width: 100% !important;
    float: none !important;
    padding-top: 40% !important;
    margin-left: 0 !important;
  }
  .academic{
     width: 90% !important;
    float: none !important;
  }
  .Solicit{
     width: 90% !important;
    float: none !important;
    margin-left: 0 !important;
    margin-top: 2rem !important;
    margin-right:0 !important;
  }
  .Friendship{
    width: 90% !important;
    float: none !important;
     margin-left: 0 !important;
     margin-top: 2rem !important;
  }
  .navigation{
      width: 95% !important;
  }
  .minist{
    margin-left: 0.3rem !important;
  }
  .lookcount{
    font-size: 0.3rem !important;
  }
 } */
/* } */
</style>
