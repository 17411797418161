import { render, staticRenderFns } from "./ContinuingBottom.vue?vue&type=template&id=6da267fa&scoped=true"
var script = {}
import style0 from "./ContinuingBottom.vue?vue&type=style&index=0&id=6da267fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da267fa",
  null
  
)

export default component.exports