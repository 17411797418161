const state = {
    t_course: localStorage.t_course ? JSON.parse(localStorage.t_course) : null
}

const getters = {
    getTCourse: state => state.t_course
}

const mutations = {
    SET_T_COURSE(state, value) {
        state.t_course = value;
        localStorage.setItem('t_course', JSON.stringify(state.t_course));
    }
}

const actions = {
    set_t_course({
        commit
    }, value) {
        commit('SET_T_COURSE', value);
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}