// 公共常量
// 查询接口权限
const ROOT = {
    // 学生端 ==============================================================================
    S: {

        // 通知公告 - 查询
        "edunotice/queryAllNoticeByStudent": 'edunotice:queryAllNoticeByStudent',
        'edunotice/updateNoticeByNoticeIdAndStudentId': "edunotice:updateNoticeByNoticeIdAndStudentId",
        // 文件申请
        'eduStudentMaterial/queryEduStuMat': "eduStudentMaterial:queryEduStuMat",
        'eduStudentMaterial/insertEduStudentMaterial': "eduStudentMaterial:insertEduStudentMaterial",
        'eduStudentMaterial/updateEduStudentMaterial': "eduStudentMaterial:updateEduStudentMaterial",
        // 我的学位
        'eduGraduateDegreeSet/queryStudentEnglish': "eduGraduateDegreeSet:queryStudentEnglish",
        'eduGraduateDegreeSet/deleteEduEnglishById': "eduGraduateDegreeSet:deleteEduEnglishById",
        'eduGraduateDegreeSet/queryEduDegStuByStuId': "eduGraduateDegreeSet:queryEduDegStuByStuId",
        // 毕业填报
        'eduOnlineQa/queryGraduationReport': "eduOnlineQa:queryGraduationReport",
        // 首页
        'eduStudentIndex/studentCourseStat': "eduStudentIndex:studentCourseStat",
        'studentCourse/querystudentCourseList': "studentCourse:querystudentCourseList",
        'eduStudentIndex/selectEduStudentPhotoList': "eduStudentIndex:selectEduStudentPhotoList",
        'eduStudentIndex/updateStudentInfo': "eduStudentIndex:updateStudentInfo",
        'eduStudentIndex/insertEduStudentPhoto': "eduStudentIndex:insertEduStudentPhoto",
        // 学籍异动
        'stuStudentChange/list': "stuStudentChange:list",
        'eduChange/queryEduChange1': "eduChange:queryEduChange1",
        'stuStudentChange/add': "stuStudentChange:add",

        // 我的课程
        'eduCourse/list': "eduCourse:list",
        'stuCourseDetail/queryStudentWork': "eduCourse:list",
        'stuCourseDetail/queryStudentWorkDetails': "eduCourse:list",
        'stuCourseDetail/queryStudentWorkRecordDetails': 'eduCourse:list',
        "eduCourseWorkSetTea/previewHomeWorkByWorkId": 'eduCourse:list',
        "stuCourseDetail/queryStudentExam": 'eduCourse:list',
        "stuCourseDetail/queryStudentExamDetails": 'eduCourse:list',
        "eduCourseExamSet/queryStudentExamById": 'eduCourse:list',
        "TeaCourseStudentExam/examPreview": 'eduCourse:list',
        "eduAnswering/queryAllEduAnswering": 'eduCourse:list', // 在线答疑
        "eduAnswering/queryRecursionAllEduAnswering": 'eduCourse:list',
        "eduAnwFabulous/fabulousEduAnsweringByIdAndTea": 'eduCourse:list',
        "eduAnswering/updateEduAnswering": 'eduCourse:list',
        "eduAnswering/addAllEduAnswering": 'eduCourse:list',
        "stuCourseDetail/queryCourseStudyDetail": 'eduCourse:list',
        "stuCourseDetail/keepStuding": 'eduCourse:list',
        "stuCourseDetail/quitLearning": 'eduCourse:list',
        "stuCourseDetail/queryCourseNotice": 'eduCourse:list',
        "stuCourseDetail/queryCourseNoticeById": 'eduCourse:list',
        "eduCourse/showModifyCourseById": 'eduCourse:list',
        "eduGraduateDesignStu/queryEduGraduateDesignByStudentId": 'eduCourse:list',

        // -------课程类型 end
        // 教学计划
        'eduTeachPlan/queryTeachPlanByStudent': "eduTeachPlan:queryTeachPlanByStudent",
        // 学费信息
        'eduFeeStudentDetail/queryFeeStuDetail': "eduFeeStudentDetail:queryFeeStuDetail",
        // 考试信息
        'eduOnlineQa/exemptionSubmit': "eduOnlineQa:exemptionSubmit",
        'eduOnlineQa/updateExemptionSubmit': "eduOnlineQa:updateExemptionSubmit",
        'eduOnlineQa/queryExemptionSubmit': "eduOnlineQa:queryExemptionSubmit",
    },
    T: {


        // 教师端 ==============================================================================
        // 在线考试批阅
        "eduExamBatch/queryEduCourseExamReview": "eduTeacherOnExam:list",
        "TeaCourseStudentExam/list": "eduTeacherOnExam:check",
        // 在线作业批阅
        "eduCourseWorkSetTea/queryEduCourseWorkSetReview": "eduTeacherCheckWork:list",
        "eduCourseWorkSetTea/teacherReviewEduCourseWork": "eduTeacherCheckWork:check",
        // 我的网盘
        "eduNetdisk/list": "eduTeacherCloud:list",
        "eduNetdisk/addFile": "eduTeacherCloud:insert",
        "eduNetdisk/exemptionSubmit": "eduTeacherCloud:insertResource",
        "eduNetdisk/delete": "eduTeacherCloud:deleted",
        "eduNetdisk/move": "eduTeacherCloud:remove",
        // 我的课程
        "teacherCourse/list": "eduTeacherCourse:list",
        // "": "eduTeacherCourse:join", // 进入学习
        // 在线考试
        "eduCourseExamSet/queryEduCourseExamSetAll": "eduTeacherExam:list",
        "eduCourseExamSet/edit": "eduTeacherExam:update",
        "TeaCourseStudentExam/examPreview": "eduTeacherExam:seeExam",
        // 首页
        "eduIndex/queryTeacherIndexData": "eduTeacher:query",
        // "eduIndex/queryTeacherIndexData": "eduTeacher:query",
        // "eduIndex/queryTeacherIndexData": "eduTeacher:query",
        // "eduIndex/queryTeacherIndexData": "eduTeacher:query",
        // 教务公告
        "teacherNotice/selectEduCourseNoticeByPage": "eduTeacherNotice:list",
        "teacherNotice/selectEduNoticeById": "eduTeacher:noticeInfo",
        // 在线答疑
        "eduAnswering/queryAllEduAnswering": "eduTeacherQuestion:list",
        "eduAnswering/queryRecursionAllEduAnswering": "eduTeacherQuestion:update",
        "eduAnwFabulous/fabulousEduAnsweringByIdAndTea": "eduTeacherQuestion:update",
        "eduAnswering/updateEduAnswering": "eduTeacherQuestion:update",
        "eduAnswering/addAllEduAnswering": "eduTeacherQuestion:update",
        // 在线作业
        "eduCourseWorkSetTea/queryAllCourseWorkSetByCourseIdByTea": "eduTeacherWork:list",
        "eduCourseWorkSetTea/queryEduCourseWorkSetPlan": "eduTeacherWorkTeam:set",
        "eduCourseWorkSetTea/queryStudentWork": "eduTeacherWork:seeWoek",
    }
}
// 常用的正则表达式
const REG = {
    PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9\s]).{10,30}$/,
    MOBILE: /^(1[35847]\d{9})$/,
    EMAIL: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/,
    SPACE: /^[^\s]+$/,
    CHINESE: /[\u4e00-\u9fa5]/,
    SPACEORCHINESE: /^[^\s\u4e00-\u9fa5]+$/,
    ONLYLETTER: /[a-zA-Z]$/,
    LETTERSTART: /^[a-zA-Z].*/, // 字母开头
    DATE: /^\d{4}[.|-|/][0|1]?\d{1}[.|-|/][0-3]?\d{1}$/,
    ZIPCODE: /^\d{6}$/,
    URL: /^(http|https|ftp):\/\/[a-z0-9\-.]+.[a-z]{2,3}(:[a-z0-9]*)?\/?([a-z0-9-._?,'+&amp;%$#=~])*$/,
    // IP: /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[1-9]).(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d).(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d).(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
    IP: /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/
}

// 学生端 首页界面切换 标识
const STU_HOME_ROUTE = {
    HOME: 'HOME', // 首页
    USERINFO: 'USERINFO', // 个人信息
    MONEYPAY: 'MONEYPAY', // 学费信息
    TAX: 'TAX', // 报税信息
    SEARCH: 'SEARCH', // 百问百答
    RULES: 'RULES', // 规章制度
    METHODS: 'METHODS', // 学习方法
    PAY: 'PAY' // 缴费说明
}

const TEACHER_HOME_ROUTE = {
    HOME: 'HOME', // 首页
    USERINFO: 'USERINFO', // 个人信息
}
// 菜单切换
const COURSE_TYPE = {
    C0: '0', // 普通课程
    C1: '1', // 毕业论文设计
    C2: '2', // 毕业大作业
    C3: '3', // 学生首页

    T0: '0', // 普通课程
    T1: '1', // 毕业论文（设计）
    T2: '2', // 毕业大作业
    T3: '3', // 教师首页
    T4: '4', // 网盘
}
const MAPPER = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    I: 9,
    J: 10,
    K: 11,
    L: 12,
    M: 13,
    N: 14,
    O: 15,
    P: 16,
    Q: 17,
    R: 18,
    S: 19,
    T: 20,
    U: 21,
    V: 22,
    W: 23,
    X: 24,
    Y: 25,
    Z: 26,
}
export {
    ROOT,
    REG,
    STU_HOME_ROUTE,
    TEACHER_HOME_ROUTE,
    COURSE_TYPE,
    MAPPER
}