import Vue from 'vue'
import axios from "axios";
import {getHeadersToken} from './util.js'
// const baseURL = 'http://ads.ketangbang.com/hnjxjy/';
const baseURL = 'http://ads.ketangbang.com/hnjxjy/';

//ads.ketangbang.com/hnjxjy/
//ads.ketangbang.com/hnjxjy/
/**
 * 创建与后台交互的参数对象
 * @returns {Object}
 */
const createBaseRequestParamData = function () {
    let obj = new Object();
    obj['x-perm'] = JSON.parse(localStorage.getItem('loginStrage'))['x-perm'];
    obj['x-token'] = JSON.parse(localStorage.getItem('loginStrage'))['x-token'];
    return obj;
}
const createSupermallReqParamData = function (data) {
    let obj = createBaseRequestParamData();
    obj.data = data;
    return obj;
};
Vue.prototype.$createReqParamData = createSupermallReqParamData;

/**
 * 后台服务
 * @type {AxiosInstance}
 */
const axiosInstance = axios.create({
    // baseURL: process.env.VUE_APP_SUPERMALL_API_BASEURL,
    baseURL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        // 'x-token': localStorage.getItem('loginStrage') != null ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
        // 'x-perm': localStorage.getItem('loginStrage') != null ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
    },
    withCredentials: false
});

const axiosInstanceData = axios.create({
    // baseURL: process.env.VUE_APP_SUPERMALL_API_BASEURL,
    baseURL,
    timeout: 30000,
    headers: {
        "Content-Type": "multipart/form-data",
        // 'x-token': localStorage.getItem('loginStrage') != null ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
        // 'x-perm': localStorage.getItem('loginStrage') != null ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
    },
    withCredentials: false
});
axiosInstanceData.interceptors.request.use(config => {
    let xperm = getHeadersToken(config.url);
    let token = JSON.parse(localStorage.getItem('loginStrage'));
    if (token != null) {
        config.headers['x-token'] = token['x-token'];
        config.headers['x-perm'] = xperm;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

axiosInstanceData.interceptors.response.use((response) => {
    /* 判断如果有loading遮盖层时，才执行close */
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    if (response.data && response.data.code) {
        if (response.data.code != 2000) {
            Vue.prototype.$message({
                type: 'warning',
                message: response.data.message
            });
            return response;
        }
    }
    return response;
}, function (error) {
    if (error.response.status===500){
        Vue.prototype.$message({
            type: 'warning',
            message: '系统异常'
        });
    }else if (error.response.status == 404){
        Vue.prototype.$message({
            type: 'warning',
            message: '资源丢失'
        });
    }else if (error.response.status == 503){
        Vue.prototype.$message({
            type: 'warning',
            message: '服务不可用'
        });
    }else if (error.response.status == 400){
        Vue.prototype.$message({
            type: 'warning',
            message: '参数异常'
        });
    }
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    // if (!error.response) {
    //     //window.location.href=process.env.VUE_APP_SUPERMALL_LOGIN_URL;
    // } else {
    //     Vue.prototype.$message({
    //         type: 'warning',
    //         message: error
    //     });
    // }
    return error;
});


const axiosInstanceDWON = axios.create({
    // baseURL: process.env.VUE_APP_SUPERMALL_API_BASEURL,
    baseURL,
    timeout: 30000,
    responseType: 'blob',
    headers: {
        "Content-Type": "application/json",
        // 'x-token': localStorage.getItem('loginStrage') != null ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
        // 'x-perm': localStorage.getItem('loginStrage') != null ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
    },
    withCredentials: false
});
axiosInstanceDWON.interceptors.request.use(config => {
    let xperm = getHeadersToken(config.url);
    let token = JSON.parse(localStorage.getItem('loginStrage'));
    if (token != null) {
        config.headers['x-token'] = token['x-token'];
        config.headers['x-perm'] = xperm;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
axiosInstanceDWON.interceptors.response.use((response) => {
    /* 判断如果有loading遮盖层时，才执行close */
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    if (response.data && response.data.code) {
        if (response.data.code != 2000) {
            Vue.prototype.$message({
                type: 'warning',
                message: response.data.message
            });
            return response;
        }
    }
    return response;
}, function (error) {
    if (error.response.status===500){
        Vue.prototype.$message({
            type: 'warning',
            message: '系统异常'
        });
    }else if (error.response.status == 404){
        Vue.prototype.$message({
            type: 'warning',
            message: '资源丢失'
        });
    }else if (error.response.status == 503){
        Vue.prototype.$message({
            type: 'warning',
            message: '服务不可用'
        });
    }else if (error.response.status == 400){
        Vue.prototype.$message({
            type: 'warning',
            message: '参数异常'
        });
    }
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    // if (!error.response) {
    //     //window.location.href=process.env.VUE_APP_SUPERMALL_LOGIN_URL;
    // } else {
    //     Vue.prototype.$message({
    //         type: 'warning',
    //         message: error
    //     });
    // }
    return error;
});

// axiosInstance.interceptors.request.use(
//     config =>{
//         if (!localStorage.getItem('loginStrage'))
//         {
//             this.$message.error("当前用户未登录");
//         }
//     }
// )
const downURL = `${baseURL}hnjxjy-core/eduEnrolStudent/upLoadEdnStuInfo`;
const teacherAdd = `${baseURL}hnjxjy-core/upload/file`;
const outImportDataTeacher = `${baseURL}hnjxjy-core/eduTeacher/importDataTeacher`;


axiosInstance.interceptors.request.use(config => {
    let xperm = getHeadersToken(config.url);
    let token = JSON.parse(localStorage.getItem('loginStrage'));
    if (token != null) {
        config.headers['x-token'] = token['x-token'];
        config.headers['x-perm'] = xperm;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


axiosInstance.interceptors.response.use((response) => {
    /* 判断如果有loading遮盖层时，才执行close */
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    if (response.data && response.data.code) {
        if (response.data.code != 2000) {
            Vue.prototype.$message({
                type: 'warning',
                message: response.data.message
            });
            return response;
        }
    }
    return response;
}, function (error) {
    if (error.response.status===500){
        Vue.prototype.$message({
            type: 'warning',
            message: '系统异常'
        });
    }else if (error.response.status == 404){
        Vue.prototype.$message({
            type: 'warning',
            message: '资源丢失'
        });
    }else if (error.response.status == 503){
        Vue.prototype.$message({
            type: 'warning',
            message: '服务不可用'
        });
    }else if (error.response.status == 400){
        Vue.prototype.$message({
            type: 'warning',
            message: '参数异常'
        });
    }
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    // if (!error.response) {
    //     //window.location.href=process.env.VUE_APP_SUPERMALL_LOGIN_URL;
    // } else {
    //     Vue.prototype.$message({
    //         type: 'warning',
    //         message: error
    //     });
    // }
    return error;
});
Vue.prototype.$axios_supermall = axiosInstance;
Vue.prototype.$axios_supermallData = axiosInstanceData;
Vue.prototype.$axios_supermallDwon = axiosInstanceDWON;
Vue.prototype.DWONWRL = downURL;
Vue.prototype.TEACHERADD = teacherAdd;
Vue.prototype.outImportDataTeacher = outImportDataTeacher;

export {
    createSupermallReqParamData,
    axiosInstance
};
