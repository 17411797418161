
export default {
    inserted: function (el, bind) {
        let permissionUrl = bind.value;
        let permission = localStorage.getItem('permissionLink');
        let permissionArr = JSON.parse(permission);
        // let permissionArr = ['eduTeachPlan:list'];
        let p = permissionArr.find(p=> p.permission === permissionUrl);
        // if (!permissionArr.includes(permissionUrl)) {
        //     el.parentNode.removeChild(el)
        // }
        if (p==null || p == '') {
            el.parentNode.removeChild(el)
        }
    }
};