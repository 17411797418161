import {
    STU_HOME_ROUTE
} from "@/tools/const"

const state = {
    // home首页 userinfo个人信息 tax报税信息 search
    stu_home_routeSwitch: STU_HOME_ROUTE.HOME
}

const getters = {
    getStuHomeRouteSwitch: state => state.stu_home_routeSwitch
}

const mutations = {
    SET_STU_HM_ROUTE_SWITCH(state, value) {
        state.stu_home_routeSwitch = value;
    }
}

const actions = {
    set_stu_hm_route_switch({
        commit
    }, value) {
        commit('SET_STU_HM_ROUTE_SWITCH', value);
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}