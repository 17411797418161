const state = {
    stu_my_course: localStorage.stu_my_course ? JSON.parse(localStorage.stu_my_course) : null
}

const getters = {
    getStuMyCourse: state => state.stu_my_course
}

const mutations = {
    SET_STU_MYCOURSE(state, value) {
        state.stu_my_course = value;
        localStorage.setItem('stu_my_course', JSON.stringify(state.stu_my_course));
    }
}

const actions = {
    set_stu_mycourse({
        commit
    }, value) {
        commit('SET_STU_MYCOURSE', value);
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}