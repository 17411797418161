<template>
  <div class="no-root">抱歉，您没有权限查看此内容</div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
/* 无权限提示的样式 */
.no-root {
  text-align: center;
  height: 200px;
  line-height: 200px;
  color: #aaa;
  user-select: none;
  background: #fff;
}
</style>