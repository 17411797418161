
import Vue from 'vue'
import VueRouter from 'vue-router'
import Header from '@/components/Header'
import Index from '../views/Index.vue'
// import Index from './views/index/index';
// import Table from './views/table/table';
// import Form from './views/form/form';
// import ue from '../views/editor/editor';

Vue.use(VueRouter, Header)

const routes = [
    // {
    //   path: '/editor',
    //   component: import(/* webpackChunkName: "about1" */ '../views/editor/editor.vue'),
    //   children: [
    //     { path: '/editor/editor', component: ue, name: 'editor', }
    //   ]
    // },
    // {
    //   path: '/editor',
    //   name:'editor',
    //   component: ()=>import(/* webpackChunkName: "about1" */ '../views/editor/editor')

    // },

    //首页-------------------------------------------------
    {
        path: '/null',
        name: 'null',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Online/null.vue')
    },
    //首页
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    //热点新闻
    {
        path: '/Hotnews',
        name: 'Hotnews',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Hotnews.vue')
    },
    // 诚招资讯Sincere consultation
    {
        path: '/Sincereconsultation',
        name: 'Sincereconsultation',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Sincereconsultation.vue')
    },

    //通知公告  Notice announcement
    {
        path: '/Noticeannouncement',
        name: 'Noticeannouncement',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Noticeannouncement.vue')
    },
    //学术交流 Academicexchange
    {
        path: '/Academicexchange',
        name: 'Academicexchange',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Academicexchange.vue')
    },
    //政策法规 Policies
    {
        path: '/Policies',
        name: 'Policies',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Policies.vue')
    },
    //招生专栏 Recruit
    {
        path: '/Recruit',
        name: 'Recruit',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Recruit.vue')
    },
    //文章详情页 Articlepage
    {
        path: '/Articlepage',
        name: 'Articlepage',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Articlepage.vue')
    },

    //后台管理层
    {
        path: '/Management',
        name: 'Management',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Management.vue')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about1" */ '../views/About.vue')
    },
    // 板块管理
    {
        path: '/news',
        name: 'News',
        component: () => import( /* webpackChunkName: "about1" */ '../views/News.vue')
    },
    //添加板块管理
    {
        path: '/Addplate',
        name: 'Addplate',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Addplate.vue')
    },
    //编辑板块管理
    {
        path: '/Editplate',
        name: 'Editplate',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Editplate.vue')
    },
    //预览  preview
    {
        path: '/preview',
        name: 'preview',
        component: () => import( /* webpackChunkName: "about1" */ '../views/preview.vue')
    },
    // 子级板块
    {
        path: '/Subplate',
        name: 'Subplate',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Subplate.vue')
    },
    // 添加子版块
    {
        path: '/Subblockadd',
        name: 'Subblockadd',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Subblockadd.vue')
    },
    //编辑子板块  Subblockedit
    {
        path: '/Subblockedit',
        name: 'Subblockedit',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Subblockedit.vue')
    },
    // management  文章管理
    {
        path: '/Articlemanagement',
        name: 'Articlemanagement',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Articlemanagement.vue')
    },
    //添加文章   Addarticle
    {
        path: '/Addarticle',
        name: 'Addarticle',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Addarticle.vue')
    },
    //编辑   Editarticle
    {
        path: '/Editarticle',
        name: 'Editarticle',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Editarticle.vue')
    },
    // 管理员页面
    {
        path: '/admin',
        name: 'Administrators',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Administrators.vue')
    },
    //教改项目
    {
        path: '/Educational/Index',
        name: 'Educational/Index',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Index.vue')
    },
    // // 研究动态
    {
        path: '/Educational/Trends',
        name: 'Educational/Trends',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Trends.vue')
    },
    // 项目应用  Application
    {
        path: '/Educational/Application',
        name: 'Educational/Application',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Application.vue')
    },
    //  项目资料 Information
    {
        path: '/Educational/Information',
        name: 'Educational/Information',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Information.vue')
    },
    // 项目负责人 Leader
    {
        path: '/Educational/Leader',
        name: 'Educational/Leader',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Leader.vue')
    },
    //  项目成果 Results
    {
        path: '/Educational/Results',
        name: 'Educational/Results',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Results.vue')
    },
    // 留言反馈 Feedback
    {
        path: '/Educational/Feedback',
        name: 'Educational/Feedback',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Feedback.vue')
    },
    // 联系我们 ContactUs
    {
        path: '/Educational/ContactUs',
        name: 'Educational/ContactUs',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/ContactUs.vue')
    },
    // 技术支持 Technical
    {
        path: '/Educational/Technical',
        name: 'Educational/Technical',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Technical.vue')
    },
    //课程详情页  IntroductionPage
    {
        path: '/Educational/IntroductionPage',
        name: 'Educational/IntroductionPage',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/IntroductionPage.vue')
    },
    // 教改项目后台  板块管理  Background
    {
        path: '/Background',
        name: 'Background',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Background.vue')
    },
    // 教改项目后台 文章管理  managementel
    {
        path: '/managementel',
        name: 'managementel',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/managementel.vue')
    },
    // 技术支持   adminitor
    {
        path: '/adminitor',
        name: 'adminitor',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/adminitor.vue')
    },
    //留言板管理    MessageBoard
    {
        path: '/MessageBoard',
        name: 'MessageBoard',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/MessageBoard.vue')
    },
    //联系我们   Contus
    {
        path: '/Contus',
        name: 'Contus',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Educational/Contus.vue')
    },

    //好客联盟
    // 新  开始
    // Home
    //首页
    {
        path: '/Alliance/Home',
        name: 'Alliance/Home',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/Home.vue')
    },
    // supermarket
    // 课程超市
    {
        path: '/Alliance/supermarket',
        name: 'Alliance/supermarket',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/supermarket.vue')
    },
    // school
    // 学校
    {
        path: '/Alliance/school',
        name: 'Alliance/school',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/school.vue')
    },
    // detailPage
    // 课程介绍页
    {
        path: '/Alliance/detailPage',
        name: 'Alliance/detailPage',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/detailPage.vue')
    },
    // PersonalCenter
    // 个人中心
    {
        path: '/Alliance/PersonalCenter',
        name: 'Alliance/PersonalCenter',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/PersonalCenter.vue')
    },
    // SchoolCloud
    // 学校云
    {
        path: '/Alliance/SchoolCloud',
        name: 'Alliance/SchoolCloud',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/SchoolCloud.vue')
    },
    // discussion
    // 课程讨论
    {
        path: '/Alliance/discussion',
        name: 'Alliance/discussion',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/discussion.vue')
    },
    // 优惠卷  coupon
    {
        path: '/Alliance/coupon',
        name: 'Alliance/coupon',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/coupon.vue')
    },
    //  信息设置   InformationSettings
    {
        path: '/Alliance/InformationSettings',
        name: 'Alliance/InformationSettings',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/InformationSettings.vue')
    },
    //  我的课程  curriculum
    {
        path: '/Alliance/curriculum',
        name: 'Alliance/curriculum',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/curriculum.vue')
    },
    // 我的讨论  MyDiscussion
    {
        path: '/Alliance/MyDiscussion',
        name: 'Alliance/MyDiscussion',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/MyDiscussion.vue')
    },
    // 我的直播 broadcast
    {
        path: '/Alliance/broadcast',
        name: 'Alliance/broadcast',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/broadcast.vue')
    },
    // 购买记录 PurchaseRecords
    {
        path: '/Alliance/PurchaseRecords',
        name: 'Alliance/PurchaseRecords',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/PurchaseRecords.vue')
    },
    // 新  结束
    // 课程大纲  CourseInformation
    {
        path: '/Alliance/CourseInformation',
        name: 'Alliance/CourseInformation',
        component: () => import( /* webpackChunkName: "about1" */ '../views/Alliance/CourseInformation.vue')
    },
    //后台主页
    {
        path: '/Alliance/Notice',
        name: 'Alliance/Notice',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Notice.vue')
    },
    // 通知公告 Announcement
    {
        path: '/Alliance/Announcement',
        name: 'Alliance/Announcement',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Announcement.vue')
    },
    // 教学计划   TeachingPlan
    {
        path: '/Alliance/TeachingPlan',
        name: 'Alliance/TeachingPlan',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/TeachingPlan.vue')
    },

    // 我要选课    ChooseCourses
    {
        path: '/Alliance/ChooseCourses',
        name: 'Alliance/ChooseCourses',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/ChooseCourses.vue')
    },
    //学习方法   Learningmethod
    {
        path: '/Alliance/Learningmethod',
        name: 'Alliance/Learningmethod',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Learningmethod.vue')
    },
    //缴费说明   Payment
    {
        path: '/Alliance/Payment',
        name: 'Alliance/Payment',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Payment.vue')
    },
    //规章制度   Regulations
    {
        path: '/Alliance/Regulations',
        name: 'Alliance/Regulations',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Regulations.vue')
    },
    //常见问题    Commonproblem
    {
        path: '/Alliance/Commonproblem',
        name: 'Alliance/Commonproblem',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Commonproblem.vue')
    },
    //留言板  MessageBoard
    {
        path: '/Alliance/MessageBoard',
        name: 'Alliance/MessageBoard',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/MessageBoard.vue')
    },
    //新增留言  Addmessage
    {
        path: '/Alliance/Addmessage',
        name: 'Alliance/Addmessage',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Addmessage.vue')
    },
    //行为统计  Behavior
    {
        path: '/Alliance/Behavior',
        name: 'Alliance/Behavior',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Behavior.vue')
    },
    //特色课程  Characteristic
    {
        path: '/Alliance/Characteristic',
        name: 'Alliance/Characteristic',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Characteristic.vue')
    },
    //我的统考  Statistics
    {
        path: '/Alliance/Statistics',
        name: 'Alliance/Statistics',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Statistics.vue')
    },
    //添加免考申请  DegreeExam
    {
        path: '/Alliance/DegreeExam',
        name: 'Alliance/DegreeExam',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/DegreeExam.vue')
    },
    //我的成绩  myScore
    {
        path: '/Alliance/myScore',
        name: 'Alliance/myScore',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/myScore.vue')
    },
    // 我要缴费 ToPay
    {
        path: '/Alliance/ToPay',
        name: 'Alliance/ToPay',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/ToPay.vue')
    },
    // 我的教材 MyTextbook
    {
        path: '/Alliance/MyTextbook',
        name: 'Alliance/MyTextbook',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/MyTextbook.vue')
    },
    //  我的课程   MyCourse
    {
        path: '/Alliance/MyCourse',
        name: 'Alliance/MyCourse',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/MyCourse.vue')
    },
    //个人信息   Personal
    {
        path: '/Alliance/Personal',
        name: 'Alliance/Personal',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Personal.vue')
    },
    // 考试信息 Examination
    {
        path: '/Alliance/Examination',
        name: 'Alliance/Examination',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Examination.vue')
    },
    //我的邮件  Mymail
    {
        path: '/Alliance/Mymail',
        name: 'Alliance/Mymail',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Mymail.vue')
    },
    //添加邮箱   Addmail
    {
        path: '/Alliance/Addmail',
        name: 'Alliance/Addmail',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Addmail.vue')
    },
    // 学籍异动  Change
    {
        path: '/Alliance/Change',
        name: 'Alliance/Change',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Alliance/Change.vue')
    },
    // 学在线
    {
        path: '/Learnonline/Homep',
        name: 'Learnonline/Homep',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Learnonline/Homep')
    },


    {
        path: '/Online/index',
        name: '/Online/index',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/index.vue')
    },

    //StatListRegister.vue  注册信息统计
    {
        path: '/Online/StatListRegister',
        name: '/Online/StatListRegister',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/StatListRegister.vue')
    },


    // sceneManage.vue 场次管理
    {
        path: '/Online/sceneManage',
        name: '/Online/sceneManage',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/sceneManage.vue')
    },

    // orderWitten.vue 考试数据-笔试
    {
        path: '/Online/orderWitten',
        name: '/Online/orderWitten',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/orderWitten.vue')
    },
    // orderOnline.vue  考试数据-在线
    {
        path: '/Online/orderOnline',
        name: '/Online/orderOnline',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/orderOnline.vue')
    },
    // examDataManage.vue  考试数据整理
    {
        path: '/Online/examDataManage',
        name: '/Online/examDataManage',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/examDataManage.vue')
    },
    // listInspectorArrange.vue  巡考教师安排
    {
        path: '/Online/listInspectorArrange',
        name: '/Online/listInspectorArrange',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/listInspectorArrange.vue')
    },
    // listBatchProcess.vue 考生安排进度
    {
        path: '/Online/listBatchProcess',
        name: '/Online/listBatchProcess',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/listBatchProcess.vue')
    },
    // listForSchool.vue  考场查看
    {
        path: '/Online/listForSchool',
        name: '/Online/listForSchool',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/listForSchool.vue')
    },
    // listForChange.vue  审核考场时间
    {
        path: '/Online/listForChange',
        name: '/Online/listForChange',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/listForChange.vue')
    },
    // listInvigilatorForSubSchool.vue   签到教师列表
    {
        path: '/Online/listInvigilatorForSubSchool',
        name: '/Online/listInvigilatorForSubSchool',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/listInvigilatorForSubSchool.vue')
    },
    // listRoomTeacherForSchool.vue  考场签到信息
    {
        path: '/Online/listRoomTeacherForSchool',
        name: '/Online/listRoomTeacherForSchool',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/listRoomTeacherForSchool.vue')
    },
    // listExamRoomStuForSchool.vue  学生签到列表
    {
        path: '/Online/listExamRoomStuForSchool',
        name: '/Online/listExamRoomStuForSchool',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/listExamRoomStuForSchool.vue')
    },


    // examScoreCheck.vue 考试成绩审核
    {
        path: '/Online/examScoreCheck',
        name: '/Online/examScoreCheck',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/examScoreCheck.vue')
    },


    // onlineScore.vue  在线成绩管理
    {
        path: '/Online/onlineScore',
        name: '/Online/onlineScore',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/onlineScore.vue')
    },

    // gradeEntry  小学分成绩录入
    {
        path: '/Online/gradeEntry',
        name: '/Online/gradeEntry',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/gradeEntry.vue')
    },


    // graduationStudentList 毕业生成绩查询
    {
        path: '/Online/graduationStudentList',
        name: '/Online/graduationStudentList',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/graduationStudentList.vue')
    },


    // generateData.vue  生成分析数据
    {
        path: '/Online/generateData',
        name: '/Online/generateData',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/generateData.vue')
    },


    //settlement.vue  订单管理
    {
        path: '/Online/settlement',
        name: '/Online/settlement',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/settlement.vue')
    },


    //  HighBaseReport.vue 高基报表
    {
        path: '/Online/HighBaseReport',
        name: '/Online/HighBaseReport',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/HighBaseReport.vue')
    },
    //  HighBaseReport.vue 高基报表
    {
        path: '/Online/superintendent',
        name: '/Online/superintendent',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/superintendent.vue')
    },
    // 公共页面
    {
        path: '/Online/null',
        name: '/Online/null',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/null.vue')
    },


    // onlineExamQuery.vue   在线考试查询
    {
        path: '/Online/onlineExamQuery',
        name: '/Online/onlineExamQuery',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/onlineExamQuery.vue')
    },


    // 统计-在线作业查询
    {
        path: '/Online/jobSearch',
        name: '/Online/jobSearch',
        component: () => import( /* webpackChunkName: "about2" */ '../views/Online/jobSearch.vue')
    },

    // //  测试我的网盘
    // {
    //   path: '/teacher/mynetworkloction',
    //   name: '/teacher/mynetworkloction',
    //   component: () => import( /* webpackChunkName: "about3" */ '../views/teacher/mynetworkloction')
    // },
    // //  测试我的回收站
    // {
    //   path: '/teacher/myrecyclebin',
    //   name: '/teacher/myrecyclebin',
    //   component: () => import( /* webpackChunkName: "about3" */ '../views/teacher/myrecyclebin')
    // },
    {
        // 教师端首页
        path: '/teacher/',
        component: () => import('../views/teacher/index'),
        children: [{
            path: '/teacher',
            redirect: '/teacher/index',
            meta: { switch: '3' }
        },
            {
                path: 'index',
                name: 'teacher-index',
                component: () => import('../views/teacher/home/index'),
                meta: { switch: '3' }
            },
            // 教务公告
            {
                path: 'notice',
                name: 'notice-index',
                component: () => import('../views/teacher/notice/index'),
                meta: { switch: '3' }
            },
            // 我的课程
            {
                path: 'course',
                name: 'course-index',
                component: () => import('../views/teacher/course/index'),
                meta: { switch: '3' }
            },
            // 在线作业
            {
                path: 'work',
                name: 'work-index',
                component: () => import('../views/teacher/work/index'),
                meta: { switch: '3' }
            },
            // 在线作业 - 查看列表
            {
                path: 'work/toView',
                name: 'work-toView',
                component: () => import('../views/teacher/work/module/ToViewList.vue')
            },
            // 在线作业 - 查看列表详情
            {
                path: 'work/toView/detail',
                name: 'work-toView-detail',
                component: () => import('../views/teacher/work/module/ToViewDetails.vue')
            },
            // 在线作业 - 作业预览
            {
                path: 'work/toView/detail/preview',
                name: 'work-toView-detail-preview',
                component: () => import('../views/teacher/work/module/Preview.vue')
            },
            // 在线作业批阅
            {
                path: 'checkWork',
                name: 'checkWork-index',
                component: () => import('../views/teacher/checkWork/index'),
                meta: { switch: '3' }
            },
            // 在线作业批阅 - 未/已批阅列表
            {
                path: 'checkWork/list',
                name: 'checkWork-list',
                component: () => import('../views/teacher/checkWork/module/CheckTab.vue')
            },
            // 在线作业批阅 - 查看
            {
                path: 'checkWork/list/info',
                name: 'checkWork-list-info',
                component: () => import('../views/teacher/checkWork/module/Preview.vue')
            },
            // 在线考试
            {
                path: 'exam',
                name: 'exam-index',
                component: () => import('../views/teacher/exam/index'),
                meta: { switch: '3' }
            },
            // 在线考试 - 编辑页
            {
                path: 'exam/edit',
                name: 'exam-edit',
                component: () => import('../views/teacher/exam/module/Edit')
            },
            // 在线考试 - 考试预览
            {
                path: 'exam/preview',
                name: 'exam-preview',
                component: () => import('../views/teacher/exam/module/ExamView.vue')
            },
            // 在线考试批阅
            {
                path: 'checkExam',
                name: 'checkExam-index',
                component: () => import('../views/teacher/checkExam/index'),
                meta: { switch: '3' }
            },
            // 在线考试批阅 - 批阅目录
            {
                path: 'checkExam/list',
                name: 'checkExam-list',
                component: () => import('../views/teacher/checkExam/module/CheckTab.vue')
            },
            // 在线考试批阅 - 答题记录
            {
                path: 'checkExam/list/preview',
                name: 'checkExam-preview',
                component: () => import('../views/teacher/checkExam/module/Preview.vue')
            },
            // 在线答疑
            {
                path: 'questions',
                name: 'questions-index',
                component: () => import('../views/teacher/questions/index'),
                meta: { switch: '3' }
            },
            // 我的网盘
            // {
            //   path: 'cloud',
            //   name: 'cloud-index',
            //   component: () => import('../views/teacher/cloud/index')
            // },
            // 我的网盘 - 切换菜单
            {
                path: 't/cloud',
                name: 't-cloud-index',
                component: () => import('../views/teacher/cloud/index'),
                meta: { switch: '4' }
            },
            // 我的回收站
            {
                path: 't/recycle',
                name: 't-recycle-index',
                component: () => import('../views/teacher/t_module/recycle/index'),
                meta: { switch: '4' }
            },
            // 课程资源
            {
                path: 't/course/resources',
                name: 't-course-resources',
                component: () => import('../views/teacher/t_module/resources/index'),
                meta: { switch: '0' }
            },
            // 课程简介
            {
                path: 't/course/introduction',
                name: 't-course-introduction',
                component: () => import('../views/teacher/t_module/introduction/index'),
                meta: { switch: '0' }
            },
            // 学习导航
            {
                path: 't/study/nav',
                name: 't-study-nav',
                component: () => import('../views/teacher/t_module/study_nav/index'),
                meta: { switch: '0' }
            },
            // 课程公告
            {
                path: 't/course/notice',
                name: 't-course-notice',
                component: () => import('../views/teacher/t_module/notice/index'),
                meta: { switch: '0' }
            },
            // 课程管理
            {
                path: 't/course/manage',
                name: 't-course-manage',
                component: () => import('../views/teacher/t_module/course_manage/index'),
                meta: { switch: '0' }
            },
            // 课程管理 - 编辑
            {
                path: 't/course/manage/edit',
                name: 't-course-manage-edit',
                component: () => import('../views/teacher/t_module/course_manage/module/Edit.vue')
            },
            // 在线答疑
            {
                path: 't/question',
                name: 't-question',
                component: () => import('../views/teacher/t_module/question/index'),
                meta: { switch: '0' }
            },
            // 题库管理
            {
                path: 't/answer/manage',
                name: 't-answer-manage',
                component: () => import('../views/teacher/t_module/answer_manage/index'),
                meta: { switch: '0' }
            },
            // 试卷管理
            {
                path: 't/exampaper/manage',
                name: 't-exampaper-manage',
                component: () => import('../views/teacher/t_module/exampaper_manage/index'),
                meta: { switch: '0' }
            },
            // 试卷管理 - 添加试卷
            {
                path: 't/exampaper/manage/add',
                name: 't-exampaper-manage-add',
                component: () => import('../views/teacher/t_module/exampaper_manage/module/Add.vue')
            },
            // 试卷管理 - 预览
            {
                path: 't/exampaper/manage/preview',
                name: 't-exampaper-manage-preview',
                component: () => import('../views/teacher/t_module/exampaper_manage/module/Preview.vue')
            },
            // 课程-在线作业
            {
                path: 't/work/online',
                name: 't-work-online',
                component: () => import('../views/teacher/t_module/work_online/index'),
                meta: { switch: '0' }
            },
            // 课程-在线作业 - 添加在线作业
            {
                path: 't/work/online/add',
                name: 't-work-online-add',
                component: () => import('../views/teacher/t_module/work_online/module/Add.vue')
            },
            // 课程-在线作业 - 预览
            {
                path: 't/work/online/preview',
                name: 't-work-online-preview',
                component: () => import('../views/teacher/t_module/work_online/module/Preview.vue')
            },
            // 课程-在线作业 - 批阅列表的批阅
            {
                path: 't/work/online/detail/checked',
                name: 't-work-online-detail-checked',
                component: () => import('../views/teacher/t_module/work_online/module/CheckedView.vue')
            },
            // 课程-在线作业 - 批阅列表的预览
            {
                path: 't/work/online/detail/preview',
                name: 't-work-online-detail-preview',
                component: () => import('../views/teacher/t_module/work_online/module/DetailPreview.vue')
            },
            // 课程-在线作业 - 查看学生作业情况
            {
                path: 't/work/online/detail',
                name: 't-work-online-detail',
                component: () => import('../views/teacher/t_module/work_online/module/Detail.vue')
            },
            // 成绩管理
            {
                path: 't/result/manage',
                name: 't-result-manage',
                component: () => import('../views/teacher/t_module/result_manage/index'),
                meta: { switch: '0' }
            },
            // 课程-在线考试
            {
                path: 't/exam/online',
                name: 't-exam-online',
                component: () => import('../views/teacher/t_module/exam_online/index'),
                meta: { switch: '0' }
            },
            // 课程-在线考试-批阅列表
            {
                path: 't/exam/online/checked',
                name: 't-exam-online-checked',
                component: () => import('../views/teacher/t_module/exam_online/module/Checked.vue')
            },
            // 课程-在线考试-批阅列表-未批阅
            {
                path: 't/exam/online/checked/view',
                name: 't-exam-online-checked-view',
                component: () => import('../views/teacher/t_module/exam_online/module/CheckedView.vue')
            },
            // 课程-在线考试-预览
            {
                path: 't/exam/online/preview',
                name: 't-exam-online-preview',
                component: () => import('../views/teacher/t_module/exam_online/module/Preview.vue')
            },
            // 课程-在线考试-预览
            {
                path: 't/exam/online/previews',
                name: 't-exam-online-previews',
                component: () => import('../views/teacher/t_module/exam_online/module/Previews.vue')
            },
            // 菜单设置
            {
                path: 't/menu/set',
                name: 't-menu-set',
                component: () => import('../views/teacher/t_module/menu_set/index'),
                meta: { switch: '0' }
            },
            // 参考选题
            {
                path: 't/selection',
                name: 't-selection',
                component: () => import('../views/teacher/t_module/selection/index'),
                meta: { switch: '1' }
            },
            // 课程文件
            {
                path: 't/course/file',
                name: 't-course-file',
                component: () => import('../views/teacher/t_module/course_file/index'),
                meta: { switch: '1' }
            },
            // 时间安排
            {
                path: 't/time/arrange',
                name: 't-time-arrange',
                component: () => import('../views/teacher/t_module/time_arrange/index'),
                meta: { switch: '1' }
            },
            // 时间安排
            {
                path: 't/time/big/arrange',
                name: 't-time-big-arrange',
                component: () => import('../views/teacher/t_module/time_arrange/indexs'),
                meta: { switch: '1' }
            },
            // 电子签名
            {
                path: 't/signature',
                name: 't-signature',
                component: () => import('../views/teacher/t_module/signature/index'),
                meta: { switch: '1' }
            },
            // 指导学生
            {
                path: 't/guide/student',
                name: 't-guide-student',
                component: () => import('../views/teacher/t_module/guide_student/index'),
                meta: { switch: '1' }
            },
            // 开题审核
            {
                path: 't/audit/report',
                name: 't-audit-report',
                component: () => import('../views/teacher/t_module/audit_report/index'),
                meta: { switch: '1' }
            },
            // 论文评审
            {
                path: 't/paper/review',
                name: 't-paper-review',
                component: () => import('../views/teacher/t_module/paper_review/index'),
                meta: { switch: '1' }
            },
            // 审核大作业
            {
                path: 't/audit/work',
                name: 't-audit-work',
                component: () => import('../views/teacher/t_module/audit_work/index'),
                meta: { switch: '2' }
            },
            {
                path: '*',
                name: 'teacher_notFound',
                component: () => import('../views/student/NotFound.vue'),
            }
        ]
    },
    //学生端首页
    {
        path: '/student/',
        // name: '/student/index',
        component: () => import('../views/student/index'),
        children: [{
            path: '/student',
            redirect: '/student/index',
            meta: { switch: '3' }
        },
            // 首页
            {
                path: 'index',
                name: 'student-index',
                component: () => import('../views/student/home/index'),
                meta: { switch: '3' }
        },
            // 通知公告
            {
                path: 'announcement',
                name: 'announcement-index',
                component: () => import('../views/student/announcement/index'),
                meta: { switch: '3' }
            },
            // 教学计划
            {
                path: 'plan',
                name: 'plan-index',
                component: () => import('../views/student/plan/index'),
                meta: { switch: '3' }
            },
            // 学费信息
            {
                path: 'tuition',
                name: 'tuition-index',
                component: () => import('../views/student/tuition/index'),
                meta: { switch: '3' }
            },
            // 我的课程
            {
                path: 'mycourse',
                name: 'mycourse-index',
                component: () => import('../views/student/mycourse/index'),
                meta: { switch: '3' }
            },
            // 考试信息
            {
                path: 'writeinfo',
                name: 'writeinfo-index',
                component: () => import('../views/student/writeinfo/index'),
                meta: { switch: '3' }
            },
            // 我的成绩
            {
                path: 'results',
                name: 'results-index',
                component: () => import('../views/student/results/index'),
                meta: { switch: '3' }
            },
            // 文件申请
            {
                path: 'file/apply',
                name: 'apply-index',
                component: () => import('../views/student/apply/index'),
                meta: { switch: '3' }
            },
            // 毕业填报
            {
                path: 'graduationEdit',
                name: 'graduationEdit-index',
                component: () => import('../views/student/graduationEdit/index'),
                meta: { switch: '3' }
            },
            // 学籍异动
            {
                path: 'move',
                name: 'move-index',
                component: () => import('../views/student/move/index'),
                meta: { switch: '3' }
            },
            // 我的学位
            {
                path: 'degree',
                name: 'degree-index',
                component: () => import('../views/student/degree/index'),
                meta: { switch: '3' }
            },
            // 个人流水
            {
                path: 'flowingWater',
                name: 'flowingWater-index',
                component: () => import('../views/student/flowingWater/index'),
                meta: { switch: '3' }
            },
            // 院校电子教材
            {
                path: 'electronicTextbook',
                name: 'electronicTextbook-index',
                component: () => import('../views/student/electronicTextbook/index'),
                meta: { switch: '3' }
            },
            // 普通课程------

            // 学习导航
            {
                path: 'c/study/nav',
                name: 'c-study-nav',
                component: () => import('../views/student/course_module/c_study_nav/index'),
                meta: { switch: '0' }
            },
            // 课程公告
            {
                path: 'c/course/notice',
                name: 'c-course-notice',
                component: () => import('../views/student/course_module/c_course_notice/index'),
                meta: { switch: '0' }
            },
            // 课程学习
            {
                path: 'c/course/study',
                name: 'c-course-study',
                component: () => import('../views/student/course_module/c_course_study/index'),
                meta: { switch: '0' }
            },
            // 在线答疑
            {
                path: 'c/online/questions',
                name: 'c-online-questions',
                component: () => import('../views/student/course_module/c_online_questions/index'),
                meta: { switch: '0' }
            },
            // 在线作业
            {
                path: 'c/online/homework',
                name: 'c-online-homework',
                component: () => import('../views/student/course_module/c_online_homework/index'),
                meta: { switch: '0' }
            },
            // 在线作业 - 详情
            {
                path: 'c/online/homework/detail',
                name: 'c-online-homework-detail',
                component: () => import('../views/student/course_module/c_online_homework/module/Detail'),
                meta: { switch: '0' }
            },
            // 在线考试
            {
                path: 'c/online/test',
                name: 'c-online-test',
                component: () => import('../views/student/course_module/c_online_test/index'),
                meta: { switch: '0' }
            },
            // 在线考试 - 详情
            {
                path: 'c/online/test/detail',
                name: 'c-online-test-detail',
                component: () => import('../views/student/course_module/c_online_test/module/Detail'),
                meta: { switch: '0' }
            },
            // 毕业大作业 -----

            // 参考选题
            {
                path: 'c/selection',
                name: 'c-selection',
                component: () => import('../views/student/course_module/c_selection/index'),
                meta: { switch: '2' }
            },
            // 课程文件
            {
                path: 'c/course/file',
                name: 'c-course-file',
                component: () => import('../views/student/course_module/c_course_file/index'),
                meta: { switch: '2' }
            },
            // 时间安排
            {
                path: 'c/schedule',
                name: 'c-schedule',
                component: () => import('../views/student/course_module/c_schedule/index'),
                meta: { switch: '2' }
            },
            // 课程视频
            {
                path: 'c/course/video',
                name: 'c-course-video',
                component: () => import('../views/student/course_module/c_course_video/index'),
                meta: { switch: '2' }
            },
            // 撰写大作业
            {
                path: 'c/write/big/homework',
                name: 'c-write-big-homework',
                component: () => import('../views/student/course_module/c_write_big_homework/index'),
                meta: { switch: '2' }
            },
            // 免做申请
            {
                path: 'c/free/apply',
                name: 'c-free-apply',
                component: () => import('../views/student/course_module/c_free_apply/index'),
                meta: { switch: '2' }
            },

            // 毕业论文（设计）
            // 操作指引
            {
                path: 'c/guide/oper',
                name: 'c-guide-oper',
                component: () => import('../views/student/course_module/c_guide_oper/index'),
                meta: { switch: '1' }
            },
            // 指导教师
            {
                path: 'c/guide/teacher',
                name: 'c-guide-teacher',
                component: () => import('../views/student/course_module/c_guide_teacher/index'),
                meta: { switch: '1' }
            },
            // 开题报告
            {
                path: 'c/report',
                name: 'c-report',
                component: () => import('../views/student/course_module/c_report/index'),
                meta: { switch: '1' }
            },
            // 提交论文
            {
                path: 'c/submit/paper',
                name: 'c-submit-paper',
                component: () => import('../views/student/course_module/c_submit_paper/index'),
                meta: { switch: '1' }
            },
            // 论文终稿
            {
                path: 'c/version/final',
                name: 'c-version-final',
                component: () => import('../views/student/course_module/c_version_final/index'),
                meta: { switch: '1' }
            },
            {
                path: '*',
                name: 'notFound',
                component: () => import('../views/student/NotFound.vue'),
            }
        ]
    },
    // 学生端在线作业 作答
    {
        path: '/work/student/answer/write',
        name: 'work-answer-write',
        component: () => import('../views/student/course_module/answer_record/WorkWrite.vue')
    },
    // 学生端 在线作业/考试 答题记录
    {
        path: '/work/student/answer/record',
        name: 'work-answer-record',
        component: () => import('../views/student/course_module/answer_record/WorkRecord.vue')
    },
    // 学生端 在线考试 作答
    {
        path: '/answer/student/write',
        name: 'answer-write',
        component: () => import('../views/student/course_module/answer_record/Write.vue')
    },
    // 学生端 在线考试答题记录痕迹
    {
        path: '/answer/student/record',
        name: 'answer-record',
        component: () => import('../views/student/course_module/answer_record/Record.vue')
    },
    // 学习视频播放
    {
        path: '/cours/student/study/details',
        name: 'cours-student-study-details',
        component: () => import('../views/student/course_module/cours_study_details/index.vue')
    },
    {
        path: '/Online/Jobassignmentbatch',
        name: '/Online/Jobassignmentbatch',
        component: () => import( /* webpackChunkName: "about3" */ '../views/Online/Jobassignmentbatch.vue')
    },
    // region foot跳转
    {
        path: '/openFoot',
        name: '/openFoot',
        meta: {
            title: ""
        },
        component: () => import( /* webpackChunkName: "about3" */ '../components/public/openFoot.vue'),
    },
    //endregion
    {
        path: '/basic111',
        name: '/basic111',
        meta: {
            title: "管理端-首页"
        },
        component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/basic.vue')
    },
    //region 管理端路由重构
    {
        path: '/basic-index',
        component: () => import('../views/basic-index.vue'),
        children: [
            //region 首页
            {
                path: '/basic',
                name: '/basic',
                meta: {
                    title: "管理端-首页"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/basic.vue'),
            },
            //endregion
            //region 基础模块
            {
                path: '/v1/base/teachsit',
                name: '/v1/base/teachsit',
                meta: {
                    title: "函授站列表"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/teachsit.vue')
            },
            {
                path: '/v1/base/gradeinfo',
                name: '/v1/base/gradeinfo',
                meta: {
                    title: "年级列表"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/gradeinfos.vue')
            },
            {
                path: '/v1/base/levelinfo',
                name: '/v1/base/levelinfo',
                meta: {
                    title: "层次列表"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/levelinfo.vue')
            },
            {
                path: '/v1/base/specialty',
                name: '/v1/base/specialty',
                mata: {
                    title: "专业列表"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/specialty.vue')
            },
            {
                path: '/v1/base/managers',
                name: '/v1/base/managers',
                mata: {
                    title: "管理员列表"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/managers.vue')
            },
            {
                path: '/v1/base/officeteacher',
                name: '/v1/base/officeteacher',
                mata: {
                    title: "总部教师列表"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/officeteacher.vue')
            },
            {
                path: '/v1/base/correspond',
                name: '/v1/base/correspond',
                mata: {
                    title: "函授站教师列表"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/correspond.vue')
            },
            {
                path: '/v1/base/courselibrary',
                name: '/v1/base/courselibrary',
                mata: {
                    title: "课程库"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/courselibrary.vue')
            },
            {
                path: '/v1/base/termmanagement',
                name: '/v1/base/termmanagement',
                mata: {
                    title: "学期管理"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/base/termmanagement.vue')
            },
            {
                path: '/public-curriculum-list',
                name: 'public-curriculum-list',
                mata: {
                    title: "公共课程资源库"
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/public-curriculum-list')
            },
            //endregion
            //region 通知模块
            //region 通知公告
            {
                path: '/v1/notice/noticeMng',
                name: '/v1/notice/noticeMng',
                mata: {
                    title: '通知公告'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/noticemng')
            },
            {
                path: '/v1/notice/mail',
                name: '/v1/notice/mail',
                mata: {
                    title: '站内信'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/mail')
            },
            //endregion
            //region 短信管理
            {
                path: '/v1/notice/studentsmstpl',
                name: '/v1/notice/studentsmstpl',
                mata: {
                    title: '短信模板'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/studentsmstpl')
            },
            {
                path: '/v1/notice/smsignature',
                name: '/v1/notice/smsignature',
                mata: {
                    title: '短信签名'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/smsignature.vue')
            },
            {
                path: '/v1/notice/studentSms',
                name: '/v1/notice/studentSms',
                mata: {
                    title: '学生短信'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/studentsms')
            },
            {
                path: '/v1/notice/smsAudit',
                name: '/v1/notice/smsAudit',
                mata: {
                    title: '短信审核'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/smsaudit')
            },
            {
                path: '/v1/notice/smsDetails',
                name: '/v1/notice/smsDetails',
                mata: {
                    title: '短信详情'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/smsdetails.vue')
            },
            //短信统计
            {
                path: '/v1/notice/smsSystem',
                name: '/v1/notice/smsSystem',
                mata: {
                    title: '短信统计'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/smssystem.vue')
            },
            //学校短信统计
            {
                path: '/v1/notice/schoolSMSsystem',
                name: '/v1/notice/schoolSMSsystem',
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/schoolsmssystem.vue')
            },
            //新生短信
            {
                path: '/v1/notice/newstudentsms',
                name: '/v1/notice/newstudentsms',
                mata: {
                    title: '新生短信'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/newstudentsms.vue')
            },
            //
            // {
            //     path: '/v1/notice/newstudentsms',
            //     name: '/v1/notice/newstudentsms',
            //     mata: {
            //         title: ''
            //     },
            //     component: () => import( /* webpackChunkName: "about3" */ '../views/v1/notice/studentsmssign.vue')
            // },
            //endregion
            //endregion
            //region 招生模块
            //region 招生批次
            {
                path: '/Online/Enrollment',
                name: '/Online/Enrollment',
                mata: {
                    title: '招生批次'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/Enrollment.vue')
            },
            {
                path: '/Online/Sitesettings',
                name: '/Online/Sitesettings',
                mata: {
                    title: '站点设置'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/Sitesettings.vue')
            },
            {
                path: '/Online/Admissionotice',
                name: '/Online/Admissionotice',
                mata: {
                    title: '招生须知'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/Admissionotice.vue')
            },
            //currentBatch.vue  当前批次
            {
                path: '/Online/currentBatch',
                name: '/Online/currentBatch',
                mata: {
                    title: '当前批次'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/currentBatch.vue')
            },
            //endregion
            //region 录取管理
            {
                path: '/Online/admissioninfo',
                name: '/Online/admissioninfo',
                mata: {
                    title: '录取信息'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/admissioninfo.vue')
            },
            {
                path: '/Online/Admission',
                name: '/Online/Admission',
                mata: {
                    title: '录取发布'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/Admission.vue')
            },
            {
                path: '/Online/Forecast',
                name: '/Online/Forecast',
                mata: {
                    title: '预报名信息'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/Forecast.vue')
            },
            //endregion
            //endregion
            //region 教务木块
            {
                path: '/Online/teachingProgramme',
                name: '/Online/teachingProgramme',
                mata: {
                    title: '教学计划'
                },
                component: () => import( /* webpackChunkName: "about3" */ '../views/Online/teachingProgramme.vue')
            },
            {
                path: '/Online/coursePlanForSchool',
                name: '/Online/coursePlanForSchool',
                mata: {
                    title: '选课管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/coursePlanForSchool.vue')
            },
            {
                path: '/Online/CourseStatistics',
                name: '/Online/CourseStatistics',
                mata: {
                    title: '选课统计'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/CourseStatistics.vue')
            },
            {
                path: '/Online/courseSelectionCourse',
                name: '/Online/courseSelectionCourse',
                mata: {
                    title: '课程选课教程'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/courseSelectionCourse.vue')
            },
            {
                path: '/Online/onlineJobManagement',
                name: '/Online/onlineJobManagement',
                mata: {
                    title: '在线作业管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/onlineJobManagement')

            },
            {
                path: '/Online/statListStu',
                name: '/Online/statListStu',
                mata: {
                    title: '在线考试管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/statListStu.vue')
            },
            //endregion
            //region 学籍模块
            //region 注册管理
            {
                path: '/Online/unGenerate',
                name: '/Online/unGenerate',
                mata: {
                    title: '未生成学号列表'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/unGenerate.vue')
            },
            {
                path: '/Online/ReportedList',
                name: '/Online/ReportedList',
                mata: {
                    title: '报到列表'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/ReportedList.vue')
            },
            {
                path: '/Online/Generated',
                name: '/Online/Generated',
                mata: {
                    title: '已生成学号列表'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/Generated.vue')
            },
            //Registration.vue 注册列表
            {
                path: '/Online/Registration',
                name: '/Online/Registration',
                mata: {
                    title: '注册列表'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/Registration.vue')
            },
            //endregion
            //region 学籍信息
            {
                path: '/Online/stuInfo',
                name: '/Online/stuInfo',
                mata: {
                    title: "学生管理"
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/stuInfo.vue')
            },
            {
                path: '/Online/statListByType',
                name: '/Online/statListByType',
                mata: {
                    title: "学生分类统计"
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/statListByType.vue')
            },
            {
                path: '/Online/baseStuchangeInfo',
                name: '/Online/baseStuchangeInfo',
                mata: {
                    title: "学籍异动管理"
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/baseStuchangeInfo.vue')
            },
            {
                path: '/Online/ceaseLearn',
                name: '/Online/ceaseLearn',
                mata: {
                    title: "休学管理"
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/ceaseLearn.vue')
            },
            {
                path: '/Online/retreatLearn',
                name: '/Online/retreatLearn',
                mata: {
                    title: "退学管理"
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/retreatLearn.vue')
            },
            {
                path: '/Online/careerManage',
                name: '/Online/careerManage',
                mata: {
                    title: "肆学管理"
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/careerManage.vue')
            },
            //endregion
            //region 其他
            {
                path: '/Online/material',
                name: '/Online/material',
                mata: {
                    title: '材料申请'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/material.vue')
            },
            {
                path: '/Online/ownCheck',
                name: '/Online/ownCheck',
                mata: {
                    title: '学籍自查'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/ownCheck.vue')
            },
            //endregion
            //endregion
            //region 考务模块
            {
                path: '/Online/examBath',
                name: '/Online/examBath',
                mata: {
                    title: '批次管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/examBath.vue')
            },
            //endregion
            //region 成绩模块
            //region 成绩管理
            {
                path: '/Online/exemption',
                name: '/Online/exemption',
                mata: {
                    title: '免考审核'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/exemption.vue')
            },
            {
                path: '/Online/scoreImport',
                name: '/Online/scoreImport',
                mata: {
                    title: '考试成绩录入'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/scoreImport.vue')
            },
            {
                path: '/Online/outLineScoreForSchool',
                name: '/Online/outLineScoreForSchool',
                mata: {
                    title: '离线成绩管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/outLineScoreForSchool.vue')
            },
            {
                path: '/Online/exemptionImport',
                name: '/Online/exemptionImport',
                mata: {
                    title: '免考成绩录入'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/exemptionImport.vue')
            },
            {
                path: '/Online/projectScore',
                name: '/Online/projectScore',
                mata: {
                    title: '毕业设计成绩'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/projectScore.vue')
            },
            {
                path: '/Online/homeworkScore',
                name: '/Online/homeworkScore',
                mata: {
                    title: '毕业大作业成绩'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/homeworkScore.vue')
            },
            {
                path: '/Online/mutualScore',
                name: '/Online/mutualScore',
                mata: {
                    title: '交互成绩管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/mutualScore.vue')
            },
            {
                path: '/Online/generalScore',
                name: '/Online/generalScore',
                mata: {
                    title: '总评成绩管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/generalScore.vue')
            },
            {
                path: '/Online/issueScore',
                name: '/Online/issueScore',
                mata: {
                    title: '发布成绩'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/issueScore.vue')
            },
            {
                path: '/Online/examScore',
                name: '/Online/examScore',
                mata: {
                    title: '考试成绩查询'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/examScore.vue')
            },
            {
                path: '/Online/gradePtion',
                name: '/Online/gradePtion',
                mata: {
                    title: '成绩修改审核'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/gradePtion.vue')
            },
            {
                path: '/Online/studentScoreInquire',
                name: '/Online/studentScoreInquire',
                mata: {
                    title: '学生成绩查询'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/studentScoreInquire.vue')
            },
            {
                path: '/Online/studentScoreStatistics',
                name: '/Online/studentScoreStatistics',
                mata: {
                    title: '学生成绩统计'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/studentScoreStatistics.vue')
            },
            //endregion
            //region 成绩分析
            {
                path: '/Online/scoreAnalyse',
                name: '/Online/scoreAnalyse',
                mata: {
                    title: '考试成绩分析'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/scoreAnalyse.vue')
            },
            {
                path: '/Online/scoreSummary',
                name: '/Online/scoreSummary',
                mata: {
                    title: '考试成绩汇总'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/scoreSummary.vue')
            },
            {
                path: '/Online/generalAnalyse',
                name: '/Online/generalAnalyse',
                mata: {
                    title: '总评成绩分析'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/generalAnalyse.vue')
            },
            {
                path: '/Online/generalSummary',
                name: '/Online/generalSummary',
                mata: {
                    title: '总评成绩汇总'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/generalSummary.vue')
            },
            //endregion
            //endregion
            //region 毕业模块
            //region 毕业设计管理
            {
                path: '/Online/graduateBath',
                name: '/Online/graduateBath',
                mata: {
                    title: '批次管理'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/graduateBath.vue')
            },
            {
                path: '/Online/examAssign',
                name: '/Online/examAssign',
                mata: {
                    title: '分配批次'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/examAssign.vue')
            },
            {
                path: '/Online/teacherList',
                name: '/Online/teacherList',
                mata: {
                    title: '指导教师列表'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/teacherList.vue')
            },
            {
                path: '/Online/assignTeachers',
                name: '/Online/assignTeachers',
                mata: {
                    title: '分配教师'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/assignTeachers.vue')
            },
            {
                path: '/Online/teacherStatistics',
                name: '/Online/teacherStatistics',
                mata: {
                    title: '指导教师统计'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/teacherStatistics.vue')
            },
            {
                path: '/Online/studentInfo',
                name: '/Online/studentInfo',
                mata: {
                    title: '学生信息'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/studentInfo.vue')
            },
            {
                path: '/Online/batchChange',
                name: '/Online/batchChange',
                mata: {
                    title: '批次异动'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/batchChange.vue')
            },
            {
                path: '/Online/typeChange',
                name: '/Online/typeChange',
                mata: {
                    title: '类型异动'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/typeChange.vue')
            },
            {
                path: '/Online/auditExamine',
                name: '/Online/auditExamine',
                mata: {
                    title: '免做审核'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/auditExamine.vue')
            },
            {
                path: '/Online/enterResults',
                name: '/Online/enterResults',
                mata: {
                    title: '录入成绩'
                },
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/enterResults.vue')
            },
            //endregion
            //region 毕业大作业管理
            {
                path: '/Online/bigGraduateBath',
                name: '/Online/bigGraduateBath',
                mata: {title: '毕业大作业-批次管理'},
                component: () => import( /* webpackChunkName: "about4" */ '../views/Online/bigGraduateBath.vue')
            },
            {
                path: '/Online/bigExamAssign',
                name: '/Online/bigExamAssign',
                mata: {title: '毕业大作业-分配批次'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigExamAssign.vue')
            },
            {
                path: '/Online/bigTeacherList',
                name: '/Online/bigTeacherList',
                mata: {title: '毕业大作业-指导教师列表'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigTeacherList.vue')
            },
            {
                path: '/Online/bigAssignTeachers',
                name: '/Online/bigAssignTeachers',
                mata: {title: '毕业大作业-分配教师'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigAssignTeachers.vue')
            },
            {
                path: '/Online/bigTeacherStatistics',
                name: '/Online/bigTeacherStatistics',
                mata: {title: '毕业大作业-教师指导统计'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigTeacherStatistics.vue')
            },
            {
                path: '/Online/bigStudentInfo',
                name: '/Online/bigStudentInfo',
                mata: {title: '毕业大作业-学生信息'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigStudentInfo.vue')
            },
            {
                path: '/Online/bigBatchChange',
                name: '/Online/bigBatchChange',
                mata: {title: '毕业大作业-批次异动'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigBatchChange.vue')
            },
            {
                path: '/Online/bigAuditExamine',
                name: '/Online/bigAuditExamine',
                mata: {title: '毕业大作业-免做审核'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigAuditExamine.vue')
            },
            {
                path: '/Online/bigEnterResults',
                name: '/Online/bigEnterResults',
                mata: {title: '毕业大作业-录入成绩'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/bigEnterResults.vue')
            },
            //endregion
            //region 毕业填报
            {
                path: '/Online/fillInSetting',
                name: '/Online/fillInSetting',
                mata: {title: '填报时间设置'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/fillInSetting')
            },
            {
                path: '/Online/fillInStudent',
                name: '/Online/fillInStudent',
                mata: {title: '学生填报时间'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/fillInStudent')
            },
            {
                path: '/Online/fillOutStudent',
                name: '/Online/fillOutStudent',
                mata: {title: '未填报列表'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/fillOutStudent.vue')
            },
            {
                path: '/Online/fillCompletedList',
                name: '/Online/fillCompletedList',
                mata: {title: '已填报列表'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/fillCompletedList.vue')
            },
            //endregion
            //region 毕业信息管理
            {
                path: '/Online/graduationConditions',
                name: '/Online/graduationConditions',
                mata: {title: '毕业条件设置'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/graduationConditions.vue')
            },
            {
                path: '/Online/graduationStudent',
                name: '/Online/graduationStudent',
                mata: {title: '毕业学生设置'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/graduationStudent.vue')
            },
            {
                path: '/Online/graduateManagement',
                name: '/Online/graduateManagement',
                mata: {title: '毕业生管理'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/graduateManagement.vue')
            },
            //endregion
            //region 学位管理
            {
                path: '/Online/declarationTimeSetting',
                name: '/Online/declarationTimeSetting',
                mata: {title: '设置申报时间'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/declarationTimeSetting.vue')
            },
            {
                path: '/Online/academicDegree',
                name: '/Online/academicDegree',
                mata: {title: '学位外语审核'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/academicDegree.vue')
            },
            {
                path: '/Online/academicDegreeRead',
                name: '/Online/academicDegreeRead',
                mata: {title: '学位资格审核-在读生'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/academicDegreeRead.vue')
            },
            {
                path: '/Online/academicDegreeOut',
                name: '/Online/academicDegreeOut',
                mata: {title: '学位资格审核-毕业生'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/academicDegreeOut.vue')
            },
            //endregion
            //endregion
            //region 费用模块
            {
                path: '/Online/tuitionStandardManagement',
                name: '/Online/tuitionStandardManagement',
                mata: {title: '学费标准管理'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/tuitionStandardManagement.vue')
            },
            {
                path: '/Online/personStandard',
                name: '/Online/personStandard',
                mata: {title: '个人标准管理'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/personStandard.vue')
            },
            {
                path: '/Online/paymentRules',
                name: '/Online/paymentRules',
                mata: {title: '缴费规则'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/paymentRules')

            },
            {
                path: '/Online/paymentManagement',
                name: '/Online/paymentManagement',
                mata: {title: '缴费管理'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/paymentManagement.vue')
            },
            {
                path: '/Online/order',
                name: '/Online/order',
                mata: {title: '订单管理'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/order.vue')
            },
            {
                path: '/Online/tuitionManagement',
                name: '/Online/tuitionManagement',
                mata: {title: '学费管理'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/tuitionManagement')
            },
            {
                path: '/Online/studentFeeDetails',
                name: '/Online/studentFeeDetails',
                mata: {title: '学生费用明细'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/studentFeeDetails.vue')
            },
            {
                path: '/Online/costStat',
                name: '/Online/costStat',
                mata: {title: '费用统计'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/costStat.vue')
            },
            {
                path: '/Online/electronicTextbookFee',
                name: '/Online/electronicTextbookFee',
                mata: {title: '电子教材费'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/electronicTextbookFee')
            },
            {
                path: '/Online/reconciliation',
                name: '/Online/reconciliation',
                mata: {title: '财务对账'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/reconciliation.vue')
            },
            {
                path: '/Online/public-course-order',
                name: '/Online/public-course-order',
                mata: {title: '公共课程资源库订单'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/public-course-order.vue')
            },
            {
                path: '/Online/personalRunningWater',
                name: '/Online/personalRunningWater',
                mata: {title: '个人流水'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/personalRunningWater.vue')
            },
            {
                path: '/Online/price-list',
                component: () => import('../views/Online/price-list.vue'),
                name: 'price-list',
                meta: { title: '提现' }
            },
            {
                path: '/Online/price-audit',
                component: () => import('../views/Online/price-audit.vue'),
                name: 'price-audit',
                meta: { title: '提现审核' }
            },
            {
                path: '/Online/freezing-price-list',
                component: () => import('../views/Online/freezing-price-list.vue'),
                name: 'freezing-price-list',
                meta: { title: '冻结资金列表' }
            },
            {
                path: '/Online/no-price-list',
                component: () => import('../views/Online/no-price-list.vue'),
                name: 'no-price-list',
                meta: { title: '不可提现余额' }
            },
            //endregion
            //region 教材模块
            {
                path: '/Online/teachingmaterial',
                name: '/Online/teachingmaterial',
                mata: {title: '教材列表'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/teachingmaterial.vue')
            },
            {
                path: '/Online/textbookCategoryManagement',
                name: '/Online/textbookCategoryManagement',
                mata: {title: '教材类别管理'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/textbookCategoryManagement.vue')
            },
            {
                path: '/Online/textbookCourse',
                name: '/Online/textbookCourse',
                mata: {title: '课程关联教材'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/textbookCourse.vue')
            },
            //endregion
            //region 统计
            {
                path: '/Online/logInStatistics',
                name: '/Online/logInStatistics',
                mata: {title: '登录统计日志'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/logInStatistics')
            },
            {
                path: '/Online/studentLearning',
                name: '/Online/studentLearning',
                mata: {title: '学生学习过程统计'},
                component: () => import( /* webpackChunkName: "about5" */ '../views/Online/studentLearning.vue')
            },
            {
                path: '/Online/teachersSupervision',
                name: '/Online/teachersSupervision',
                mata: {title: '教师督学助学统计'},
                component: () => import( /* webpackChunkName: "about" */ '../views/Online/teachersSupervision.vue')
            },
            {
                path: '/Online/courseSupervision',
                name: '/Online/courseSupervision',
                mata: {title: '课程督学助学统计'},
                component: () => import( /* webpackChunkName: "about" */ '../views/Online/courseSupervision.vue')
            },
            {
                path: '/Online/courselearningProcessStatistics',
                name: '/Online/courselearningProcessStatistics',
                mata: {title: '课程学习过程统计'},
                component: () => import( /* webpackChunkName: "about" */ '../views/Online/courselearningProcessStatistics')
            },
            {
                path: '/Online/studyRecord',
                name: '/Online/studyRecord',
                mata: {title: '学生学习记录'},
                component: () => import( /* webpackChunkName: "about" */ '../views/Online/studyRecord.vue')
            },
            //endregion
            //region 系统模块
            //region 系统
            {
                path: '/v1/system/systemusers',
                name: '/v1/system/systemusers',
                mata: {title: '系统用户'},
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/systemusers.vue')
            },
            {
                path: '/v1/system/management',
                name: '/v1/system/management',
                mata: {title: '角色管理'},
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/management.vue')
            },
            {
                path: '/v1/system/permission',
                name: '/v1/system/permission',
                mata: {title: '角色权限分配'},
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/permission.vue')
            },
            {
                path: '/v1/system/configuration',
                name: '/v1/system/configuration',
                mata: {title: '菜单设置'},
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/configuration.vue')
            },
            //endregion
            //region 用户管理
            {
                path: '/v1/system/account',
                name: '/v1/system/account',
                mata: {title: '学生账号'},
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/account.vue')
            },
            {
                path: '/v1/system/teacheraccount',
                name: '/v1/system/teacheraccount',
                mata: {title: '教师账户管理'},
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/teacheraccount.vue')
            },
            {
                path: '/v1/system/correspondence',
                name: '/v1/system/correspondence',
                mata: {title: '函授站教师管理'},
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/correspondence.vue')
            },
            //endregion
            //systemusers.vue  系统用户管理
            {
                path: '/v1/system/systemusers',
                name: '/v1/system/systemusers',
                component: () => import( /* webpackChunkName: "about" */ '../views/v1/system/systemusers.vue')
            },
            //endregion
        ]
    },


    /**重组router结构20210702.zhang */



    /**2.基础 */

    // 批阅教师管理  reviewTeacher
    {
        path: '/v1/base/reviewteacher',
        name: '/v1/base/reviewteacher',
        component: () => import( /* webpackChunkName: "about" */ '../views/v1/base/reviewteacher.vue')
    },


    // taxinformation.vue  学生报税信息
    {
        path: '/v1/base/taxinformation',
        name: '/v1/base/taxinformation',
        component: () => import( /* webpackChunkName: "about" */ '../views/v1/base/taxinformation.vue')
    },
    /**3.通知 */

    //学习必备
    {
        path: '/v1/notice/necessaryForLearning',
        name: '/v1/notice/necessaryForLearning',
        component: () => import( /* webpackChunkName: "about" */ '../views/v1/notice/necessaryforlearning')
    },


]

// 解决冗余导航
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes:routes
})

export default router