import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import './tools/axios-request'
import './assets/css/global.css'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixin/mixin.js'
import axios from 'axios'
// // 引入echarts
import echarts from 'echarts'
import '@/assets/svgIcon/index'
// 全局引入视频插件
import VideoPlayer from 'vue-video-player'
import china from 'echarts/map/json/china.json' // 引入json文件
import pinyin from 'js-pinyin'
import permission from "./permission";
// 自定义组件
import component from "@/tools/component";
import {getHeadersToken} from './tools/util.js'

//导入路由权限权限
import '@/router/permission.js'
Vue.use(component);
// 学生端/教师端 控制功能显隐
import {
    sysPerKey
} from './tools/util'
// Vue.use(ElementUI);
Vue.use(ElementUI, {
    size: "small"
});
// echarts.registerMap('china', china) // 在echarts中注册使用这个文件
Vue.prototype.$echarts = echarts;
Vue.prototype.$pinyin = pinyin;
Vue.config.productionTip = false;

axios.defaults.timeout = 900000000000000;


Vue.prototype.$sysPerKey = sysPerKey

Vue.directive('permission', permission);
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
};

Date.prototype.formatToLocalDateTime = function (format) {
    if (format) {
        return this.format(format);
    } else {
        format = "yyyy-MM-dd HH:mm:ss";
        return this.format(format);
    }

};

Vue.prototype.$stringFormat = function stringFormat(formatted, args) {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}


//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// const loginToken=window.localStorage.getItem
// axios.defaults.baseURL = 'http://ads.ketangbang.com/hnjxjy/', // 'http://192.168.0.15:10000/';
axios.defaults.baseURL = 'https://ads.ketangbang.com/hnjxjy/',
// axios.defaults.baseURL ='http://192.168.0.15:10000/',
axios.interceptors.request.use(config => {
    let xperm = getHeadersToken(config.url);
    let token = JSON.parse(localStorage.getItem('loginStrage'));
    if (token != null) {
        config.headers['x-token'] = token['x-token'];
        config.headers['x-perm'] = xperm;
    }
    return config
})
axios.interceptors.response.use((response) => {
    /* 判断如果有loading遮盖层时，才执行close */
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    if (response.data && response.data.code) {
        if (response.data.code != 2000) {
            Vue.prototype.$message({
                type: 'warning',
                message: response.data.message
            });
            return response;
        }
    }
    return response;
}, function (error) {
    if (error.response.status===500){
        Vue.prototype.$message({
            type: 'warning',
            message: '系统异常'
        });
    }else if (error.response.status == 404){
        Vue.prototype.$message({
            type: 'warning',
            message: '资源丢失'
        });
    }else if (error.response.status == 503){
        Vue.prototype.$message({
            type: 'warning',
            message: '服务不可用'
        });
    }else if (error.response.status == 400){
        Vue.prototype.$message({
            type: 'warning',
            message: '参数异常'
        });
    }
    if (document.getElementsByClassName("circular").length > 0) {
        Vue.prototype.$loading().close();
    }
    // if (!error.response) {
    //     //window.location.href=process.env.VUE_APP_SUPERMALL_LOGIN_URL;
    // } else {
    //     Vue.prototype.$message({
    //         type: 'warning',
    //         message: error
    //     });
    // }
    return error;
});
Vue.mixin(mixins);
Vue.prototype.$axios = axios
Vue.prototype.$axios_supermall = axios
Vue.prototype.$axios_supermallData = axios

new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App)
}).$mount('#app')
