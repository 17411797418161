import store from '../store'
import router from '../router'

// 图片转为base64格式
export const imageToBase64 = (img) => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/jpeg" + ext);
    return dataURL;
};
// base64格式转为File文件类型
export const base64ToFile = (urlData, fileName) => {
    let arr = urlData.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bytes = atob(arr[1]); // 解码base64
    let n = bytes.length
    let ia = new Uint8Array(n);
    while (n--) {
        ia[n] = bytes.charCodeAt(n);
    }
    return new File([ia], fileName, {
        type: mime
    });
}

// base64格式转为File文件类型
export const getHeadersToken = (url) => {
    let json = localStorage.getItem('permissionLink');
    let permissionArr = JSON.parse(json);
    if (permissionArr == null || permissionArr == "") {
        return "perm_query";
    }
    url = url.toString();
    if (url.indexOf("/")!=0){
        url = "/"+url;
    }
    if (url.indexOf("?") >= 0) {
        url = url.substring(0, url.indexOf('?'));
    }
    let p = permissionArr.find(p => p.menUrl === url);
    if (p != null && p != '' && p.menUrl != null && p.menUrl != '') {
        return p.permission
    } else {
        return "perm_query"
    }
}

// 学生端/教师端 权限处理
export const sysPerKey = (key) => {
    const isTeacher = router.currentRoute.path.includes('/teacher');
    const sysPer = isTeacher ? store.getters.getSysPerT : store.getters.getSysPer;
    const permission = sysPer.find((f) => f.permission === key);
    return permission
}