import router from ".";

// 路由前置守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("_grecaptcha") || "";
  
  if (to.meta.keepAlive) {
    if (token) {
      next();
    } else {
      next("/login");
    }
  }else{
    next()
  }
});



export default router;
