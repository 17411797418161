import {
    STU_HOME_ROUTE
} from "@/tools/const"
// 教师/学生端 通用信息
const state = {
    user: localStorage.user ? JSON.parse(localStorage.user) : null,
    authentication: localStorage.loginStrage ? JSON.parse(localStorage.loginStrage) : {
        'x-perm': null,
        'x-token': null
    },
}

const getters = {
    getUser: state => state.user,
    getAuth: state => state.authentication,
}

const mutations = {
    SET_USER(state, user) {
        state.user = user;
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    SET_AUTH(state, auth) {
        state.authentication = auth;
        localStorage.setItem('loginStrage', JSON.stringify(state.authentication))
    }
}

const actions = {
    set_user({
        commit
    }, user) {
        commit('SET_USER', user);
    },

    set_auth({
        commit
    }, auth) {
        commit('SET_AUTH', auth);
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}