const state = {
    // 切换菜单
    // T0: '0', // 普通课程
    // T1: '1', // 毕业论文（设计）
    // T2: '2', // 毕业大作业
    // T3: '3', // 教师首页
    // T4: '4', // 网盘
    teacherMenuSwitch: sessionStorage.teacher_menu_switch ? JSON.parse(sessionStorage.teacher_menu_switch) : '3',
    // 菜单下的功能，控制显示隐藏
    sysPermissionsT: [],
}

const getters = {
    getTeacherMenuSwitch: state => state.teacherMenuSwitch,
    getSysPerT: state => state.sysPermissionsT,
}

const mutations = {
    SET_TEACHER_MENU_SWITCH(state, num) {
        state.teacherMenuSwitch = num;
        sessionStorage.setItem('teacher_menu_switch', JSON.stringify(state.teacherMenuSwitch))
    },
    SET_SYS_PER_T(state, list) {
        state.sysPermissionsT = list;
    },
}

const actions = {
    set_teacher_menu_switch({
        commit
    }, num) {
        commit('SET_TEACHER_MENU_SWITCH', num);
    },
    set_sys_per_teacher(context, list) {
        context.commit('SET_SYS_PER_T', list);
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}