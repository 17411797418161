<template>
  <div>
    <div class="Continuing">
      <!-- <router-link to="/news"> -->
      <p style="cursor: pointer;" @click="loginer()">首页 | 登录</p>
      <!-- </router-link> -->
    </div>
    <div class="box">
      <div class="bx">
        <img src="@/assets/b.png" alt srcset class="adc" style />
      </div>
      <img src="@/assets/a.png" class="logo-text" alt srcset />
      <router-link to="/">
        <img src="@/assets/st.png" alt class="im" style />
        <p class="homepage">首页</p>
      </router-link>
      <router-link to="/Hotnews">
        <img src="@/assets/st.png" alt class="im1" style />
        <p class="homepage1">热点新闻</p>
      </router-link>
      <router-link to="/Sincereconsultation">
        <img src="@/assets/st.png" alt class="im2" style />
        <p class="homepage2">诚招资讯</p>
      </router-link>
      <router-link to="/Noticeannouncement">
        <img src="@/assets/st.png" alt class="im3" style />
        <p class="homepage3">通知公告</p>
      </router-link>
      <router-link to="/Academicexchange">
        <img src="@/assets/st.png" alt class="im4" style />
        <p class="homepage4">学术交流</p>
      </router-link>
      <router-link to="/Online/index">
        <img src="@/assets/st.png" alt class="im5" style />
        <p class="homepage5">成教监管</p>
      </router-link>
      <router-link to="/Alliance/Home">
        <img src="@/assets/st.png" alt class="im6" style />
        <p class="homepage6">好课联盟</p>
      </router-link>
      <router-link to>
        <img src="@/assets/st.png" alt class="im7" style />
        <p class="homepage7">教改项目</p>
      </router-link>
    </div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      center
      :append-to-body="true"
      :lock-scroll="false"
      :width="dialogWidth"
    >
      <!--用户名登录表单-->
      <div>
        <h2
          style="padding:0.1rem;text-align:center;background-color:#006005;color:#fff;font-weight:500;"
        >账号登陆</h2>
        <div style="padding:1rem;text-align:center;">
          手机号：
          <el-input v-model="phone" placeholder="请输入登录名"></el-input>
        </div>
        <div style="padding-left:1rem;text-align:center;">
          密码：
          <el-input v-model="password" placeholder="请输入密码" show-password></el-input>
        </div>
        <div style="padding:0.8rem;text-align:center;">
          <el-button type="primary" @click="login()">登录</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<style scoped>
.Continuing {
  padding: 0.8rem;
}
.el-dialog__wrapper {
  z-index: 10000000000000 !important;
}
.el-input {
  width: 75%;
}
.el-button--primary {
  background-color: #006005;
  border-color: #006005;
}
.Continuing p {
  font-size: 0.8rem;
  width: 70%;
  text-align: right;
  margin: 0 auto;
  color: #336666;
}
.Education {
  background-color: #ededd9;
  position: relative;
  height: 150px;
}
.further {
  width: 70%;
  margin: 0 auto;
  position: absolute;
  left: 14%;
}
.further img {
  width: 36%;
}
.tradition {
  font-size: 0.08rem;
}
.tradition img {
  width: 100%;
}
.box {
  position: relative;
  height: 168px;
  background-color: #ededd9;
}

.bx {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.logo-text {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.im {
  position: absolute;
  top: 74%;
  left: 53%;
  z-index: 900;
}
.im1 {
  position: absolute;
  top: 74%;
  left: 57%;
  z-index: 901;
}
.im2 {
  position: absolute;
  top: 74%;
  left: 61%;
  z-index: 902;
}
.im3 {
  position: absolute;
  top: 74%;
  left: 65%;
  z-index: 903;
}
.im4 {
  position: absolute;
  top: 74%;
  left: 69%;
  z-index: 904;
}
.im5 {
  position: absolute;
  top: 74%;
  left: 73%;
  z-index: 905;
}
.im6 {
  position: absolute;
  top: 74%;
  left: 77%;
  z-index: 906;
}
.im7 {
  position: absolute;
  top: 74%;
  left: 81%;
  z-index: 907;
}
.homepage {
  font-size: 0.8rem;
  position: absolute;
  left: 55%;
  top: 82%;
  z-index: 902;
  color: #fdffca;
}
.homepage1 {
  font-size: 0.8rem;
  position: absolute;
  left: 58.4%;
  top: 82%;
  z-index: 903;
  color: #fdffca;
}
.homepage2 {
  font-size: 0.8rem;
  position: absolute;
  left: 62.4%;
  top: 82%;
  z-index: 904;
  color: #fdffca;
}
.homepage3 {
  font-size: 0.8rem;
  position: absolute;
  left: 66.4%;
  top: 82%;
  z-index: 905;
  color: #fdffca;
}
.homepage4 {
  font-size: 0.8rem;
  position: absolute;
  left: 70.3%;
  top: 82%;
  z-index: 906;
  color: #fdffca;
}
.homepage5 {
  font-size: 0.8rem;
  position: absolute;
  left: 74.3%;
  top: 82%;
  z-index: 907;
  color: #fdffca;
}
.homepage6 {
  font-size: 0.8rem;
  position: absolute;
  left: 78.3%;
  top: 82%;
  z-index: 908;
  color: #fdffca;
}
.homepage7 {
 font-size: 0.8rem;
  position: absolute;
  left: 82.3%;
  top: 82%;
  z-index: 909;
  color: #fdffca;
}
.adc {
  width: 27%;
  top: 17%;
  left: 34%;
  position: absolute;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .box {
    height: 84px;
  }
  .im,
  .im2,
  .im3,
  .im1,
  .im4,
  .im5,
  .im6,
  .im7 {
    display: none;
  }
  .homepage {
    font-size: 0.01rem !important;
    position: absolute !important;
    left: 1% !important;
    top: 50% !important;
    z-index: 902 !important;
    color: #fdffca !important;
    padding: 0.3rem;
    background-color: #006450;
    width: 6%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .homepage1 {
    font-size: 0.1rem;
    position: absolute;
    left: 10.7%;
    top: 50%;
    z-index: 903;
    color: #fdffca;
    padding: 0.3rem;
    background-color: #006450;
    width: 9%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .homepage2 {
    font-size: 0.1rem;
    position: absolute;
    left: 23.5%;
    top: 50%;
    z-index: 904;
    color: #fdffca;
    padding: 0.3rem;
    background-color: #006450;
    width: 9%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .homepage3 {
    font-size: 0.13rem;
    position: absolute;
    left: 36.4%;
    top: 50%;
    z-index: 905;
    color: #fdffca;
    padding: 0.3rem;
    background-color: #006450;
    width: 9%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .homepage6 {
    font-size: 0.13rem;
    position: absolute;
    left: 74.8%;
    top: 50%;
    z-index: 906;
    color: #fdffca;
    padding: 0.3rem;
    background-color: #006450;
    width: 9%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .homepage4 {
    font-size: 0.13rem;
    position: absolute;
    left: 49.2%;
    top: 50%;
    z-index: 907;
    color: #fdffca;
    padding: 0.3rem;
    background-color: #006450;
    width: 9%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .homepage5 {
    font-size: 0.13rem;
    position: absolute;
    left: 62%;
    top: 50%;
    z-index: 908;
    color: #fdffca;
    padding: 0.3rem;
    background-color: #006450;
    width: 9%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .homepage7 {
    position: absolute;
    top: 50%;
    left: 87.6%;
    /* z-index: 10000 !important; */
    /* width: 19% !important; */
    padding: 0.3rem;
    background-color: #006450;
    width: 9%;
    border-top-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .adc {
    width: 43%;
    top: 0%;
    left: 30%;
    position: absolute;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .box {
    height: 62px !important;
  }
  .im {
    position: absolute;
    top: 70%;
    left: 41%;
    width: 10%;
  }
  .homepage {
    font-size: 0.1rem;
    position: absolute;
    left: 44%;
    top: 81%;
    color: #fdffca;
  }
  .im1 {
    position: absolute;
    top: 70%;
    left: 48%;
    width: 10%;
  }
  .homepage1 {
    font-size: 0.13rem;
    position: absolute;
    left: 49.5%;
    top: 82%;
    color: #fdffca;
  }
  .im2 {
    position: absolute;
    top: 70%;
    left: 55%;
    width: 10%;
  }
  .homepage2 {
    font-size: 0.13rem;
    position: absolute;
    left: 56.9%;
    top: 82%;
    color: #fdffca;
  }
  .im3 {
    position: absolute;
    top: 70%;
    left: 62%;
    z-index: 9997;
    width: 10%;
  }
  .homepage3 {
    font-size: 0.13rem;
    position: absolute;
    left: 64%;
    top: 82%;
    color: #fdffca;
  }
  .im4 {
    position: absolute;
    top: 70%;
    left: 69%;
    width: 10%;
  }
  .homepage4 {
    font-size: 0.13rem;
    position: absolute;
    left: 70.6%;
    top: 82%;
    color: #fdffca;
  }
  .im5 {
    position: absolute;
    top: 70%;
    left: 76%;
    z-index: 9995;
    width: 10%;
  }
  .homepage5 {
    font-size: 0.13rem;
    position: absolute;
    left: 77.5%;
    top: 82%;
    color: #fdffca;
  }
  .im6 {
    position: absolute;
    top: 70%;
    left: 83%;
    width: 10%;
  }
  .homepage6 {
    font-size: 0.13rem;
    position: absolute;
    left: 84.6%;
    top: 82%;
    color: #fdffca;
  }
  .im7 {
    position: absolute;
    top: 70%;
    left: 90%;
    width: 10%;
  }
  .homepage7 {
    font-size: 0.13rem;
    position: absolute;
    left: 91.6%;
    top: 82%;
    color: #fdffca;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .box {
    height: 90px !important;
  }
  .im {
    position: absolute;
    top: 65%;
    left: 43%;
    width: 9%;
  }
  .homepage {
    font-size: 0.1rem;
    position: absolute;
    left: 46%;
    top: 77%;
    color: #fdffca;
  }
  .im1 {
    position: absolute;
    top: 65%;
    left: 49%;
    width: 9%;
  }
  .homepage1 {
    font-size: 0.13rem;
    position: absolute;
    left: 51%;
    top: 77%;
    color: #fdffca;
  }
  .im2 {
    position: absolute;
    top: 65%;
    left: 55%;
    width: 9%;
  }
  .homepage2 {
    font-size: 0.13rem;
    position: absolute;
    left: 57%;
    top: 77%;
    color: #fdffca;
  }
  .im3 {
    position: absolute;
    top: 65%;
    left: 61%;
    width: 9%;
  }
  .homepage3 {
    font-size: 0.13rem;
    position: absolute;
    left: 63%;
    top: 77%;
    color: #fdffca;
  }
  .im4 {
    position: absolute;
    top: 65%;
    left: 67%;
    width: 9%;
  }
  .homepage4 {
    font-size: 0.13rem;
    position: absolute;
    left: 69%;
    top: 77%;
    color: #fdffca;
  }
  .im5 {
    position: absolute;
    top: 65%;
    left: 73%;
    width: 9%;
  }
  .homepage5 {
    font-size: 0.13rem;
    position: absolute;
    left: 75%;
    top: 77%;
    color: #fdffca;
  }
  .im6 {
    position: absolute;
    top: 65%;
    left: 79%;
    width: 9%;
  }
  .homepage6 {
    font-size: 0.13rem;
    position: absolute;
    left: 81%;
    top: 77%;
    color: #fdffca;
  }
  .im7 {
    position: absolute;
    top: 65%;
    left: 85%;
    width: 9%;
  }
  .homepage7 {
    font-size: 0.13rem;
    position: absolute;
    left: 87%;
    top: 77%;
    color: #fdffca;
  }
}
</style>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dialogFormVisible: false,
      input: "",
      dialogWidth: 0,
      phone: "",
      password: "",
      id:""
    };
  },
  created() {
    this.setDialogWidth();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },
  methods: {
    setDialogWidth() {
      var val = document.body.clientWidth;
      const def = 450; // 默认宽度
      if (val < def) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = def + "px";
      }
    },
    //登录弹框
    loginer() {
      this.dialogFormVisible = true;
    },
    //提交登录
    login() {
      var password = this.password;
      var phone = this.phone;
      axios({
        method: "get",
        url:
          "/hngca/registryController/userlogin?password=" +
          this.password +
          "&phone=" +
          this.phone +
          ""
        // data:{
        //   password:this.password,
        //   phone:this.phone
        // }
      })
        .then(res => {
          if (res.data.responseCode == 20003) {
            alert("手机号格式不正确!!!");
          }  if (res.data.responseCode == 20004) {
            alert("用户名和密码错误!!!!");
          }  if (res.data.responseCode == 20001) {
            alert("登录成功！！！");
            this.$router.push({ name: "News" });
            this.id = res.data.returnObject.id
            // alert(this.id)
          }
        })
        .catch(res => {});
    }
  }
};
</script>
